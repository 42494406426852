import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "./Include/Sidebar";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllTransaction } from "../redux/Action/transactionAction";
import { Pagination } from "react-bootstrap";
import { formatPrice } from "../components/commonFunction";
import moment from "moment";

export default function Transactions() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8)
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const transList = useSelector((state) => state.transaction);

  const handleSearch = (e) => {
    setSearch(e);
  };

  const handlelimit = (e) => {
    setLimit(e);
  };

  useEffect(() => {
    dispatch(getAllTransaction({ page, limit, search }));
  }, [page, limit, search]);

  return (
    <Layout>
      <div class="main-content">
        <div class="page-content">
          {/* <!-- start page title --> */}
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <div>
                  <span class="small_text">Buff Browz</span>
                  <div className="d-flex justify-content-center align-items-center">
                    <h4 class="mb-sm-0 font-size-28">Transactions </h4>
                    <p className="total">0</p>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- end page title --> */}

            {/* <!-- start search row --> */}
            <div class="row mb-4">
              <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12">
                <input
                  type="search"
                  class="form-control cmn_fields"
                  onChange={(e) => handleSearch(e.target.value)}
                  aria-describedby="emailHelp"
                  placeholder="Search Here"
                />
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <select
                    class="form-control cmn_fields"
                    id="exampleFormControlSelect1"
                  >
                    <option>Date Range</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-12">
                <input
                  type="number"
                  class="form-control cmn_fields"
                  onChange={(e) => handlelimit(e.target.value)}
                  aria-describedby="emailHelp"
                  placeholder="Total: 0"
                />
              </div>

              <div className="col-xxl-5 col-xl-2 col-lg-12 col-md-6 col-4 d-flex justify-content-end">
                <span>
                  <NavLink to="">
                    {" "}
                    <button class="btn create_cmn new responsive_new">
                      Export
                    </button>
                  </NavLink>
                </span>
              </div>
            </div>
          </div>
          {/* <!-- end search row --> */}

          <div class="row">
            <div class="col-xl-12">
              <div class="members_tbl height-set">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                      <h4 class="title_text">Transactions</h4>
                    </div>

                    <div class="table-responsive">
                      <table
                        id=""
                        class="table dt-responsive dealers_table nowrap w-100"
                      >
                        <thead>
                          <tr>
                            <th>Transaction No.</th>
                            <th>
                              Date{" "}
                              <img
                                class="filt_arrow"
                                src={
                                  require("../assets/images/greydownarrow.svg")
                                    .default
                                }
                              />
                            </th>
                            <th>Customer</th>
                            <th>Course / Description</th>
                            <th>Stripe ID</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody class="td_color">
                          {
                            Array.isArray(transList?.transaction) && transList?.transaction?.length > 0 ?
                              transList?.transaction?.map((res, index) => {
                                return <tr key={index + 1}>
                                  <td>{(index + 1)?.toString()?.padStart(3, "0")}</td>
                                  <td>{moment(res?.createdAt).format('DD/MM/YYYY')}</td>
                                  <td>{res?.customer_id?.full_name || 'N/A'}</td>
                                  <td>{res?.courseId?.course_title || 'N/A'}</td>
                                  <td><div className="tooltip-main">{res?.session_id ? res?.session_id.slice(0,20)+ "..." : 'N/A'}
                                  <div className="tooltip-custom"><p>{res?.session_id}</p></div>
                                  </div></td>
                                  <td>£{formatPrice(res?.amount || 0)}</td>
                                </tr>
                              })
                              :
                              <div className="no-data-div">
                                <p className="no-data">No data Found</p>
                              </div>
                          }

                        </tbody>
                      </table>

                    </div>
                  </div>
                  <Pagination
                    totalStuff={transList}
                    limit={limit}
                    setPage={setPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- container-fluid --> */}
      </div>
    </Layout>
  );
}
