import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { modalData } from "./Constants";
import { useDispatch } from "react-redux";
import { deleteAccount, sendResetPass } from "../../redux/Action/userAction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { deleteAdmin } from "../../redux/Action/adminAction";
import { deleteChapter, deleteCourse, getChapters } from "../../redux/Action/coursesAction";
import { deleteProduct } from "../../redux/Action/productsAction";
import { deleteCreator } from "../../redux/Action/creatorActions";
import { deleteChannel, getChannels } from "../../redux/Action/channelAction";

const CommonModal = ({ show, setShow, type, id, page, search, limit }) => {
  console.log("type", type)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { title, body } = modalData[type];

  const handleSubmit = () => {
    switch (type) {
      case "deleteAccount":
        dispatch(deleteAccount(id)).then(({ payload }) => {
          if (payload.success) {
            toast.success(payload.message);
            navigate("/users");
          } else if (!payload.success) {
            toast.error(payload.message);
          }
        });
        handleClose();
        break;

      case "resetPass":
        dispatch(sendResetPass(id)).then(({ payload }) => {
          if (payload.success) {
            toast.success(payload.message);
          } else if (!payload.success) {
            toast.error(payload.message);
          }
        });
        handleClose();
        break;

      case "deleteAdmin":
        dispatch(deleteAdmin(id));
        handleClose();
        break;

      case "deleteCourse":
        dispatch(deleteCourse(id));
        handleClose();
        break;

      case "deleteProduct":
        dispatch(deleteProduct(id));
        handleClose();
        break;

      case "deleteCreator":
        dispatch(deleteCreator(id));
        handleClose();
        break;

      case "deleteChannel":
        dispatch(deleteChannel(id)).then(({ payload }) => {
          if (payload.success) {
            toast.success(payload.message);
            dispatch(getChannels({ page, limit, search }))
          } else if (!payload.success) {
            toast.error(payload.message);
          }
        });
        handleClose();
        break;

      case "deleteChapter":
        dispatch(deleteChapter(id)).then(({ payload }) => {
          console.log("payload------", payload)
          if (payload?.success) {
            toast.success(payload.message);
            navigate(`/ManageCourses`)

          } else if (!payload.success) {
            toast.error(payload.message);
          }
        });
        handleClose();
        break;

      default:
        break;
    }
  };

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          {/* <Button className="btn cre_new_one" onClick={handleClose}>
            No
          </Button> */}
          {/* <Button className="btn cre_new" onClick={handleSubmit}>
            Yes
          </Button> */}
          <button className="btn cre_new_one" onClick={handleClose}>
            No
          </button>
          <button className="btn cre_new" onClick={handleSubmit}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommonModal;
