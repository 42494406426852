import React, { useEffect, useState } from 'react'

export default function Createaccount() {
    const [device, setDevice] = useState('');
   
  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/Android/i.test(userAgent)) {
      setDevice('Android');
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
  
   
    window.location.replace("buffeducation://buffeducation");
    } else {

       window.location.replace("buffeducation://buffeducation");


    }
  }, []);
  return (
    <div>
      <h1>Device Check</h1>
      {device && <p>You are using a {device} device.</p>}
    </div>
  )
}
