import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../services/Api";
export const adminLogin = createAsyncThunk(
    "auth/login",
    async (data,{dispatch}) => {
      try {
        const response = await API.post("/auth/login", data);
        console.log(response,"response");
        return response.data;
      } catch (error) {
        console.log(error, "error");
      }
    }
  );

  