import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetail,
  editUserDetails,
  approveuserprofile,
  changeUserRole,
} from "../../redux/Action/userAction";
import moment from "moment";
import { Formik } from "formik";
import CommonModal from "../../components/Modals/CommonModal";
import { useState } from "react";
import { toast } from "react-toastify";
import UserPurchases from "./UserPurchases";

export default function UserDetails() {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [type, setType] = useState();
  const [purchaseView, setPurchaseView] = useState(false);
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state?.adminAction?.userDetail);

  useEffect(() => {
    dispatch(getUserDetail(id));
  }, []);

  const handleModalOpen = (type) => {
    setShow(true);
    setType(type);
  };

  const changestatus = async (e) => {
    let valueInNumber = e.target.value == "Live" ? 1 : 0;
    let res = await dispatch(
      approveuserprofile({
        adminapproved: valueInNumber,
        id: id,
      })
    );
    if (res?.payload?.status_code == 200) {
      await dispatch(getUserDetail(id));
    }
  };

  const changeRole = async (e) => {
    let res = await dispatch(
      changeUserRole({
        role: Number(e.target.value),
        userId: id,
      })
    );
    console.log("res------", res)
    if (res?.payload) {
      await dispatch(getUserDetail(id));
    }
  };


  console.log(userDetail, "userDetail-------");

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid px-0">
          <div className="create_tab">
            {/* <!-- start page title --> */}

            <div className="row align-items-center">
              <div className="col-6 d-flex align-items-center">
                <Link to="/users">
                  <img src={require("../../assets/images/Component.png")} />
                </Link>
                <h4 className="mb-sm-0 font-size-28">
                  {userDetail?.full_name}
                </h4>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <p className="sub_heading">
                  Joined: {moment(userDetail?.createdAt).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          </div>
          {/* <!-- end page title --> */}

          {type && (
            <CommonModal show={show} setShow={setShow} type={type} id={id} />
          )}

          <div className="row">
            <div className="col-lg-12 d-flex justify-content-end">
              <button
                onClick={() => setPurchaseView(false)}
                className={`btn ${!purchaseView ? " cre_new " : "cre_new_one"}`}
              >
                Overview
              </button>
              <button
                onClick={() => setPurchaseView(true)}
                className={`btn ${purchaseView ? " cre_new " : "cre_new_one"}`}
              >
                Purchases
              </button>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          {!purchaseView ? (
            <div className="row">
              <div className="col-xl-12">
                <div className="members_tbl">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      full_name: userDetail?.full_name,
                      phone_number: userDetail?.phone_number,
                      userId: id,
                      address1: userDetail?.address1
                        ? userDetail?.address1
                        : "--",
                      address2: userDetail?.address2
                        ? userDetail?.address2
                        : "--",
                      city: userDetail?.city ? userDetail?.city : "--",
                      country: userDetail?.country ? userDetail?.country : "--",
                      post_code: userDetail?.post_code
                        ? userDetail?.post_code
                        : "--",
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.full_name) errors.full_name = "Required*";
                      if (!values.phone_number)
                        errors.phone_number = "Required*";
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      dispatch(editUserDetails(values)).then(({ payload }) => {
                        if (payload.success) {
                          toast.success(payload.message);
                        } else if (!payload.success) {
                          toast.error(payload.message);
                        }
                      });
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="card mt-4">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                              <h4 className="title_text">Contact Details</h4>
                              <button className="btn cre_new">Save</button>
                            </div>

                            <div className="contact_details">
                              <div className="form_fields_main mb-2">
                                <label>Full Name</label>
                                <div className="req-text-box">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Full Name"
                                    name="full_name"
                                    onChange={handleChange}
                                    value={values?.full_name}
                                  />
                                  {errors?.full_name && touched?.full_name && (
                                    <span className="formik-errors">
                                      {errors?.full_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                              {/* <div className="form_fields_main mb-2">
                          <label>Surname</label>
                          <input
                            type="text"
                            class="form-control"
                            id=""
                            placeholder=""
                            value="Rose"
                          />
                        </div> */}
                              <div className="form_fields_main mb-2">
                                <label>Email</label>
                                <div className="req-text-box">
                                  <input
                                    type="email"
                                    class="form-control"
                                    value={userDetail?.email}
                                  />
                                  {errors?.email && touched?.email && (
                                    <span className="formik-errors">
                                      {errors?.email}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="form_fields_main mb-2">
                                <label>Phone</label>
                                <div className="req-text-box d-flex">
                                  <input
                                    type="number"
                                    class="form-control phone-code"
                                    value="+44"
                                    placeholder="+44"
                                  />
                                  <input
                                    style={{
                                      borderRadius: "0 20px 20px 0",
                                    }}
                                    type="number"
                                    class="form-control phone-num-input"
                                    placeholder="0000000000"
                                    name="phone_number"
                                    onChange={handleChange}
                                    value={values?.phone_number}
                                  />
                                  {errors?.phone_number &&
                                    touched?.phone_number && (
                                      <span className="formik-errors">
                                        {errors?.phone_number}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-start align-items-center flex-wrap mb-3 position-relative">
                              <h4 className="title_text">Additional Details</h4>
                            </div>
                            <div className="contact_details">
                              <div className="row">
                                <div className="col-xxl-5 col-xl-5 col-lg-5">
                                  <div className="form_fields_main additional">
                                    <label className="width-100">
                                      {" "}
                                      Address
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="address1"
                                      placeholder="Address line 1"
                                      onChange={handleChange}
                                      value={values?.address1}
                                    />

                                    <br />
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="address2"
                                      placeholder="Address line 2"
                                      onChange={handleChange}
                                      value={values?.address2}
                                    />

                                    <br />
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="city"
                                      placeholder="City"
                                      onChange={handleChange}
                                      value={values?.city}
                                    />

                                    <br />
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="country"
                                      placeholder="Country"
                                      onChange={handleChange}
                                      value={values?.country}
                                    />

                                    <br />
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="post_code"
                                      placeholder="Post Code"
                                      onChange={handleChange}
                                      value={values?.post_code}
                                    />
                                    {/* <span className="formik-errors req-text">
                                    Required*
                                  </span> */}
                                  </div>
                                </div>
                                <div className="col-xxl-1 col-xl-0 col-lg-1"></div>
                                <div className=" col-xxl-4 col-xl-5 col-lg-6">
                                  <div className="additional_info">
                                    <ul>
                                      <li>
                                        Last Log In
                                        <p>
                                          {userDetail?.lastLogin
                                            ? moment(
                                                userDetail?.lastLogin
                                              ).format("DD/MM/YYYY")
                                            : "--"}
                                        </p>
                                      </li>
                                      <li>
                                        Account Status
                                        <p>
                                          {" "}
                                          {userDetail?.adminapproved == 1 ? (
                                            <>
                                              <img
                                                src={require("../../assets/images/check_circle.png")}
                                              />{" "}
                                              Active
                                            </>
                                          ) : (
                                            <>
                                              <svg
                                                width="28"
                                                height="28"
                                                viewBox="0 0 31 30"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M14.787 0.0304416C14.8095 0.0288601 14.8316 0.0275949 14.8541 0.0260134C21.1464 -0.363357 27.4682 3.65655 29.7833 9.51957C32.0984 15.3826 30.2277 22.6364 25.3663 26.6493C20.5048 30.6623 13.0268 31.1254 7.70723 27.7425C3.55663 25.1029 0.976423 20.2923 0.781548 15.4101C0.582243 10.4195 3.09633 5.74036 7.12387 2.85156C9.36241 1.24537 12.0296 0.214847 14.787 0.0304416ZM13.3605 8.51562C13.4304 9.16689 13.4564 9.82638 13.5791 10.4675C13.8578 11.9241 14.1663 13.3756 14.4991 14.8205C14.6652 15.5414 14.8964 16.2486 15.1283 16.9518C15.2239 17.2415 15.4485 17.4531 15.7832 17.456C16.1201 17.4588 16.3216 17.2399 16.4438 16.9578C16.5478 16.7174 16.6266 16.4637 16.6953 16.2103C17.4124 13.5705 18.1268 10.9293 18.2141 8.17021C18.2337 7.54709 18.1556 6.94295 17.8585 6.3812C17.4178 5.54805 16.7266 5.08277 15.787 5.08625C14.8594 5.08941 14.1742 5.55501 13.7373 6.37297C13.3782 7.04512 13.3412 7.77388 13.3605 8.51562ZM15.8123 20.5013C14.6525 20.4915 13.8136 21.3573 13.8117 22.5659C13.8098 23.7469 14.6288 24.6193 15.7522 24.6319C16.9275 24.6452 17.7414 23.8115 17.7569 22.5785C17.7721 21.3671 16.9708 20.5111 15.8123 20.5013Z"
                                                  fill="#8B8B8B"
                                                />
                                              </svg>
                                              <span className="ms-1">
                                                InActive
                                              </span>
                                            </>
                                          )}
                                        </p>
                                      </li>
                                      <li>
                                        User Role
                                        <p className="d-flex align-items-center">
                                          <select
                                            class="form-select"
                                            aria-label="Default select example"
                                            value={
                                              userDetail?.role == 0
                                                ? "0"
                                                : "1"
                                            }
                                            onChange={(e) => changeRole(e)}
                                          >
                                            <option
                                              value="1"
                                              // disabled={
                                              //   userDetail?.role == 0
                                              //     ? true
                                              //     : false
                                              // }
                                            >
                                              Creator
                                            </option>
                                            <option
                                              value="0"
                                              // disabled={
                                              //   userDetail?.role == 1
                                              //     ? true
                                              //     : false
                                              // }
                                            >
                                              Regular
                                            </option>
                                          </select>{" "}
                                        </p>
                                      </li>
                                      <li>
                                        Live / Suspend
                                        <p className="d-flex align-items-center">
                                          {userDetail?.adminapproved == 1 ? (
                                            <img
                                              src={require("../../assets/images/check_circle.png")}
                                            />
                                          ) : (
                                            <svg
                                              width="30"
                                              height="30"
                                              viewBox="0 0 30 30"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M14.0592 0.0271202C20.3309 -0.368995 26.6434 3.6126 28.9816 9.44689C31.3264 15.2978 29.4938 22.5613 24.6538 26.5994C19.8138 30.6375 12.3396 31.1389 7.00331 27.7836C2.42945 24.9075 -0.26642 19.4017 0.0208763 14.0363C0.311984 8.60198 3.88014 3.76335 8.73679 1.43795C10.3864 0.648151 12.1795 0.15292 14.0062 0.0302392C14.0239 0.0291996 14.0415 0.0281599 14.0592 0.0271202ZM15.0576 12.4973C14.9668 12.4138 14.8875 12.3445 14.8119 12.2706C13.2767 10.7711 11.7425 9.27049 10.2065 7.77129C10.0877 7.65519 9.96464 7.54187 9.83434 7.43894C9.00606 6.78533 7.76088 7.03173 7.24451 7.94907C6.82587 8.69244 6.95791 9.50303 7.62053 10.1681C9.14885 11.7026 10.682 13.2323 12.2128 14.7645C12.2866 14.8386 12.3559 14.9173 12.4651 15.0348C12.3656 15.1155 12.2755 15.1758 12.2003 15.251C10.7025 16.7468 9.20603 18.2436 7.71063 19.7421C7.59315 19.8595 7.47948 19.9822 7.37621 20.1125C6.72468 20.9332 6.97836 22.1915 7.895 22.7003C8.64045 23.1137 9.43857 22.9796 10.1161 22.3191C11.6874 20.7862 13.2559 19.2506 14.8261 17.7168C14.9003 17.6444 14.979 17.5757 15.0951 17.4693C15.1807 17.5775 15.2396 17.67 15.3155 17.7459C16.8105 19.2444 18.307 20.7415 19.8044 22.2373C19.915 22.3478 20.0311 22.4542 20.1531 22.552C20.9859 23.2194 22.2463 22.9695 22.7602 22.0377C23.1712 21.2922 23.033 20.4875 22.3672 19.8204C20.8375 18.2872 19.3044 16.7575 17.7733 15.2261C17.7005 15.1533 17.6326 15.0757 17.5404 14.9766C17.6464 14.883 17.7313 14.8158 17.8072 14.7399C19.3047 13.2438 20.8018 11.7473 22.2972 10.2495C22.4078 10.139 22.5142 10.0229 22.6119 9.90088C23.2776 9.0688 23.0229 7.8049 22.09 7.29581C21.3404 6.88687 20.5416 7.02723 19.8731 7.69435C18.3475 9.21747 16.8247 10.7441 15.3006 12.2686C15.2254 12.3424 15.1467 12.4127 15.0576 12.4973Z"
                                                fill="#FF0A0A"
                                              />
                                            </svg>
                                          )}
                                          <select
                                            class="form-select"
                                            aria-label="Default select example"
                                            value={
                                              userDetail?.adminapproved == 1
                                                ? "Live"
                                                : "Suspend"
                                            }
                                            onChange={(e) => changestatus(e)}
                                          >
                                            <option
                                              value="Live"
                                              disabled={
                                                userDetail?.adminapproved == 1
                                                  ? true
                                                  : false
                                              }
                                            >
                                              Live
                                            </option>
                                            <option
                                              value="Suspend"
                                              disabled={
                                                userDetail?.adminapproved == 0
                                                  ? true
                                                  : false
                                              }
                                            >
                                              Suspend
                                            </option>
                                          </select>{" "}
                                        </p>
                                      </li>
                                      <li>
                                        Password Reset
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleModalOpen("resetPass")
                                          }
                                          className="reset_btn"
                                        >
                                          Reset Password
                                        </button>
                                      </li>
                                      <li>
                                        Delete Account
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleModalOpen("deleteAccount")
                                          }
                                          className="delete_btn"
                                        >
                                          Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          ) : (
            <UserPurchases id={id} />
          )}
        </div>

        {/* <!-- container-fluid --> */}
      </div>
    </div>
  );
}
