import React from "react";
import { NavLink, Link } from "react-router-dom";
import Sidebar from "./Include/Sidebar";

export default function ChapterOneWithQuiz() {
  return (
    <div>
      {/* <!-- Begin page --> */}
      <div id="layout-wrapper">
        {/* <!-- ========== Left Sidebar Start ========== --> */}
        <div className="vertical-menu">
          <Sidebar />
        </div>
        {/* <!-- Left Sidebar End --> */}

        {/* <!-- ============================================================== -->
            <!-- Start right Content here -->
            <!-- ============================================================== --> */}
        <div className="main-content">
          <div className="container-fluid">
            <div className="create_tab">
              {/* <!-- start page title --> */}

              <div className="row align-items-center">
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <Link className="back_img" to="/ManageCourses">
                      <img
                        src={
                          require("../assets/images/Expand_left.svg").default
                        }
                      />
                    </Link>
                    <h4 className="mb-sm-0 font-size-28">
                      Eyebrow Tinting Basics
                    </h4>
                  </div>
                  <p className="sub_heading">Joined: 26/08/2021</p>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}
            <div className="row mb-4">
              <div className="col-lg-12 d-flex justify-content-end">
                <Link to="/Course-Overview">
                  <button className="btn cre_new_one">Overview</button>
                </Link>
                <Link to="/Chapter-One">
                  <button className="btn cre_new">Chapter 1</button>
                </Link>
                <Link to="#">
                  <button className="btn cre_new_one">Chapter 2</button>
                </Link>
                <Link to="#">
                  <button className="btn cre_new_one">Chapter 3</button>
                </Link>
                <Link to="/Create-Chapter">
                  <button className="btn cre_new_one plus">+</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="members_tbl">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                        <h4 className="title_text"> Details</h4>
                        <div>
                          <Link to="/Create-Chapter">
                            <button className="btn cre_new_one">Edit</button>
                          </Link>
                        </div>
                      </div>
                      <div className="contact_details create_channel create_course chap_one">
                        <form>
                          <div className="form_fields_main mb-4">
                            <label>Chapter Cover Image</label>

                            <div className="d-flex align-items-end">
                              <div className="img_uploaded_box">
                                <img
                                  src={require("../assets/images/massage.png")}
                                />
                              </div>
                              <div className="up_m_btn" style={{ opacity: 0 }}>
                                <input
                                  type="file"
                                  class="upload_field"
                                  id=""
                                  placeholder="Upload Image"
                                />
                                <p className="upload_btn">Upload Image</p>
                              </div>
                            </div>
                          </div>

                          <div className="form_fields_main mb-4">
                            <label>Chapter Title</label>
                            <input
                              type="text"
                              class="form-control"
                              id=""
                              placeholder=""
                              value="Course Introduction"
                            />
                          </div>

                          <div className="form_fields_main mb-4">
                            <label>Upload Video</label>
                            <div className="up_m_btn d-none">
                              <input
                                type="file"
                                class="upload_field"
                                id=""
                                placeholder="Upload Image"
                              />
                              <p className="upload_btn">Upload</p>
                            </div>
                            <div className="upload_progress">
                              <p>Video Upload Successful</p>
                            </div>
                          </div>

                          <div className="form_fields_main mb-4">
                            <label>Course Description</label>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="5"
                              value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla sit amet diam nec dictum. Integer hendrerit lorem et turpis pellentesque, quis mollis velit tincidunt. Donec sed maximus sem. Curabitur eu finibus turpis. Mauris sed consectetur eros, vel efficitur libero. Aliquam erat volutpat. Ut varius massa vel ex venenatis pharetra. Mauris convallis leo ac purus scelerisque mollis. Nam sodales quam eu hendrerit efficitur. Sed elit odio, pulvinar sit amet sagittis vel, efficitur vel lectus. Vestibulum tincidunt, sapien vitae interdum egestas, nulla nunc scelerisque tortor, vitae bibendum neque erat non est. Vestibulum et mauris imperdiet, venenatis odio tristique, sodales leo. Suspendisse commodo sit amet tellus vitae blandit. Nunc sed mi augue. Mauris maximus ut lorem vel ultricies. Sed non nibh sem."
                            />
                          </div>
                          <div className="form_fields_main mb-4">
                            <label>What You’ll Learn</label>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla sit amet diam nec dictum. Integer hendrerit lorem et turpis pellentesque, quis mollis velit tincidunt. Donec sed maximus sem. Curabitur eu finibus turpis. Mauris sed consectetur eros, vel efficitur libero. Aliquam erat volutpat. Ut varius massa vel ex venenatis pharetra. Mauris convallis leo ac purus scelerisque mollis. Nam sodales quam eu hendrerit efficitur"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 d-flex justify-content-end mb-4">
              <Link to="#">
                <button className="btn cre_new_one add">Add Question</button>
              </Link>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-12">
                  <div className="members_tbl create_quiz">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-start align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Question 1</h4>
                        </div>
                        <div className="contact_details">
                          <form>
                            <div className="form_fields_main mb-3">
                              <label>Question Title</label>
                              <input
                                type="text"
                                class="form-control main-question"
                                id=""
                                placeholder="Type Here.."
                              />
                            </div>

                            <div className="form_fields_extra mt-4">
                              <label>Set Answers</label>
                              <div className="extra_bottom d-flex align-items-top">
                                <div>
                                  <ol>
                                    <li>
                                      <input
                                        type="text"
                                        class="form-control"
                                        id=""
                                        placeholder="Type Here.."
                                      />
                                    </li>
                                    <br />
                                    <li>
                                      <input
                                        type="text"
                                        class="form-control"
                                        id=""
                                        placeholder="Type Here.."
                                      />
                                    </li>
                                  </ol>
                                </div>
                                <div className="inputs_extra">
                                  <p>Correct?</p>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <br />
                                  <br />
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault1"
                                  />
                                  <br />
                                  <br />
                                  <img
                                    src={require("../assets/images/plus.png")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 d-flex justify-content-end">
                              <Link to="#">
                                <button className="btn delete_one">
                                  Delete
                                </button>
                              </Link>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- container-fluid --> */}
        </div>
        {/* <!-- End Page-content --> */}
      </div>
    </div>
  );
}
