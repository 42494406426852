import React from "react";

import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Layout from "../components/layout/Layout";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import { useDispatch } from "react-redux";
import { changePassword } from "../redux/Action/adminAction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <Row className="justify-content-center space-set">
            <Col xxl={8} xl={10} lg={8}>
              <Container fluid>
                <Card className="border-0 p-5 rounded-card">
                  <h2 className="text-center">Change Password</h2>

                  <Formik
                    initialValues={{
                      oldpassword: "",
                      newpassword: "",
                      confirmpassword: "",
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (!values?.oldpassword) {
                        errors.oldpassword = "Required*";
                      }

                      if (!values?.newpassword) {
                        errors.newpassword = "Required*";
                      } else if (values?.newpassword?.length < 8) {
                        errors.newpassword =
                          "Password must be 8 characters long";
                      }

                      if (values?.newpassword != values?.confirmpassword) {
                        errors.confirmpassword = "Password doesn't match";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      dispatch(changePassword(values))
                        .then((response) => {
                          if (response.payload?.success) {
                            toast.success(response.payload?.message);
                            navigate(-1);
                          }
                        })
                        .catch((error) => console.log(error));

                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <FormikForm className="change-password-form px-5">
                        <Row className="mb-4">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Old Password</Form.Label>
                            <Field
                              className="form-control"
                              type="password"
                              placeholder="Old Password"
                              name="oldpassword"
                            />
                            <div className="error-msg">
                              <ErrorMessage name="oldpassword" component="div" />
                            </div>
                          </Form.Group>
                        </Row>
                        <Row className="mb-4">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>New Password</Form.Label>
                            <Field
                              className="form-control"
                              type="password"
                              placeholder="New Password"
                              name="newpassword"
                            />
                            <div className="error-msg">
                              <ErrorMessage name="newpassword" component="div" />
                            </div>
                          </Form.Group>
                        </Row>
                        <Row className="mb-4">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Confirm Password</Form.Label>
                            <Field
                              className="form-control"
                              type="password"
                              placeholder="Confirm Password"
                              name="confirmpassword"
                            />
                            <div className="error-msg">
                              <ErrorMessage
                                name="confirmpassword"
                                component="div"
                              />
                            </div>
                          </Form.Group>
                        </Row>
                        <Form.Group
                          className="mt-4  mb-5 model-btn text-center"
                          controlId="formGridAddress2"
                        >
                          <Button type="submit" variant="primary" size="lg">
                            Submit
                          </Button>
                        </Form.Group>
                      </FormikForm>
                    )}
                  </Formik>
                </Card>
              </Container>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
