import React from 'react'

const Search = ({setSearch}) => {
    
  return (
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 ">
    <input
      type="text"
      className="form-control cmn_fields"
      id=""
      aria-describedby="emailHelp"
      placeholder="Search Here"
      onChange={(e) => setSearch(e.target.value)} 
    />
  </div>
  )
}

export default Search