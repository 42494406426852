import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { createProduct } from "../redux/Action/productsAction";
import { toast } from "react-toastify";

export default function AddProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [prevImg, setPrevImg] = useState({
    product1: "",
    product2: "",
    product3: "",
    product4: "",
  });

  const [imageCount, setImageCount] = useState(1);

  const handleImageUpload = (e, key, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      setPrevImg((prev) => ({
        ...prev,
        [key]: URL.createObjectURL(file),
      }));
      setFieldValue(key, file);
    }
  };

  const handleImageDelete = (key, setFieldValue) => {
    setPrevImg((prev) => ({
      ...prev,
      [key]: "",
    }));
    setFieldValue(key, "");
  };

  const handleAddProduct = () => {
    if (imageCount < 4) {
      setImageCount(imageCount + 1);
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid px-0">
          <div className="create_tab">
            <div className="row align-items-center">
              <div className="col-12 d-flex align-items-center">
                <Link to="/ManageProducts" className="d-flex">
                  <img src={require("../assets/images/Component.png")} alt="icon" />
                  <h4 className="mb-sm-0 font-size-28">Create New Product</h4>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="members_tbl">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                      <h4 className="title_text">Details</h4>
                    </div>
                    <div className="contact_details product-add">
                      <Formik
                        initialValues={{
                          product1: "",
                          product2: "",
                          product3: "",
                          product4: "",
                          name: "",
                          manufacturer_name: "",
                          desc: "",
                          link: "",
                          price: "",
                        }}
                        validate={(values) => {
                          const errors = {};
                          ["name", "manufacturer_name", "desc", "price"].forEach(
                            (key) => {
                              if (!values[key]) {
                                errors[key] = "Required*";
                              }
                            }
                          );
                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          console.log("values", values)
                          const formdata = new FormData();
                          Object.keys(values).forEach((key) => {
                            if (values[key]) {
                              formdata.append(key, values[key]);
                            }
                          });

                          dispatch(createProduct(formdata))
                            .then((response) => {
                              if (response.payload.success) {
                                navigate("/ManageProducts");
                                toast.success(response.payload.message);
                              }
                            })
                            .catch((error) => toast.error(error.message));
                          setSubmitting(false);
                        }}
                      >
                        {({ isSubmitting, setFieldValue, values }) => (
                          <Form>
                            <div className="form_fields_main upload mb-4">
                              <label>Product Images</label>
                              <div className="row">
                                <div className="col-lg-6"></div>
                              </div>
                              <div className="d-flex align-items-center flex-wrap">
                                {Array.from({ length: imageCount }).map((_, index) => {
                                  const key = `product${index + 1}`;
                                  return (
                                    <div className="img_uploaded_box" key={key}>
                                      {prevImg[key] && (
                                        <button
                                          type="button"
                                          onClick={() => handleImageDelete(key, setFieldValue)}
                                        >
                                          <img
                                            src={require("../assets/images/Close_square_fill.png")}
                                            alt="delete"
                                          />
                                        </button>
                                      )}
                                      <div className="up_m_btn-product">
                                        <p>Upload Image</p>
                                        <input
                                          type="file"
                                          className="upload_field w-100"
                                          placeholder="Upload Image"
                                          onChange={(e) =>
                                            handleImageUpload(e, key, setFieldValue)
                                          }
                                        />
                                      </div>
                                      {prevImg[key] && (
                                        <img
                                          className="show-img"
                                          src={prevImg[key]}
                                          alt="uploaded"
                                        />
                                      )}
                                    </div>
                                  );
                                })}

                                {imageCount < 4 && (
                                  <div
                                    className="add-another text-center"
                                    onClick={handleAddProduct}
                                  >
                                    <img
                                      src={require("../assets/images/plus.svg").default}
                                      alt="add"
                                    />
                                    <br />
                                    <p>Add another</p>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form_fields_main mb-4">
                              <label>Product Name</label>
                              <Field
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                              />
                              <ErrorMessage name="name" component="div" />
                            </div>

                            <div className="form_fields_main mb-4">
                              <label>Manufacturer Name</label>
                              <Field
                                name="manufacturer_name"
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                              />
                              <ErrorMessage
                                name="manufacturer_name"
                                component="div"
                              />
                            </div>
                            <div className="form_fields_main mb-4">
                              <label>Product Description</label>
                              <Field
                                as="textarea"
                                name="desc"
                                className="form-control"
                                rows="5"
                                placeholder="Type Here..."
                              />
                              <ErrorMessage name="desc" component="div" />
                            </div>

                            <div className="form_fields_main mb-4">
                              <label>Product Link</label>
                              <Field
                                name="link"
                                as="textarea"
                                className="form-control"
                                placeholder="Type Here..."
                              />
                              <ErrorMessage name="link" component="div" />
                            </div>
                            <div className="form_fields_main mb-4">
                              <label>Price (£)</label>
                              <Field
                                name="price"
                                type="number"
                                className="form-control"
                                placeholder="£0.00"
                              />
                              <ErrorMessage name="price" component="div" />
                            </div>
                            <div className="form_fields_main mb-4 justify-content-end">
                              <button
                                type="submit"
                                className="create_cmn new c_create"
                              >
                                Create Product
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
