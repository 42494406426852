﻿﻿import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { startstopLoading } from "../redux/Reducer/loaderSlice";
import { toast } from "react-toastify";
import { createManageAdmin } from "../redux/Action/adminAction";

export default function Createadmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectObj = {
    "Super Admin": 0,
    Admin: 1,
    Developer: 2,
    "Full Access": 0,
    "View Only": 1,
  };

  return (
    <div className="main-content">
      <div className="container-fluid">
        <div className="create_tab">
          {/* <!-- start page title --> */}

          <div className="row align-items-center">
            <div className="col-6 d-flex align-items-center">
              <Link to="/Manage-admin">
                {" "}
                <img src={require("../assets/images/Component.png")} />
              </Link>
              <h4 className="mb-sm-0 font-size-28">New Admin</h4>
            </div>
          </div>
        </div>
        {/* <!-- end page title --> */}
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="members_tbl create-admin">
              <Formik
                initialValues={{
                  email: "",
                  full_name: "",
                  position: "",
                  access_level: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Required*";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }
                  if (!values.full_name) {
                    errors.full_name = "Required*";
                  }
                  if (!values.position) {
                    errors.position = "Required*";
                  }
                  if (!values.access_level) {
                    errors.access_level = "Required*";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  // console.log(values);
                  dispatch(startstopLoading(true));

                  dispatch(createManageAdmin(values)).then(({ payload }) => {
                    if (payload.success) {
                      toast.success(payload.message);
                      navigate("/Manage-admin");
                      dispatch(startstopLoading(false));
                    } else if (!payload.success) {
                      toast.warning(payload.message);
                      dispatch(startstopLoading(false));
                    }
                  });

                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-start align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Contact Details</h4>
                        </div>

                        <div className="contact_details">
                          <div className="form_fields_main">
                            <label>Name</label>
                            <input
                              type="text"
                              className="form-control width-645"
                              name="full_name"
                              onChange={handleChange}
                              value={values.full_name}
                              placeholder="Full Name"
                            />
                            {errors?.full_name && touched?.full_name && (
                              <span className="formik-errors">
                                {errors?.full_name}
                              </span>
                            )}
                          </div>
                          <div className="form_fields_main">
                            <label>Position</label>
                            <select
                              class="form-control"
                              id="exampleFormControlSelect1"
                              name="position"
                              onChange={handleChange}
                              value={values.position}
                            >
                              <option hidden={true}>Select</option>
                              <option value={0}>Super Admin</option>
                              <option value={1}>Admin</option>
                              <option value={2}>Developer</option>
                            </select>
                            {errors?.position && touched?.position && (
                              <span className="formik-errors">
                                {errors?.position}
                              </span>
                            )}
                          </div>
                          <div className="form_fields_main">
                            <label>Email</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Type Here.."
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                            />
                            {errors?.email && touched?.email && (
                              <span className="formik-errors">
                                {errors?.email}
                              </span>
                            )}
                          </div>
                          <div className="form_fields_main">
                            <label>Access Level</label>
                            <select
                              name="access_level"
                              onChange={handleChange}
                              value={values.access_level}
                              class="form-control"
                              id="exampleFormControlSelect2"
                            >
                              <option hidden={true}>Select</option>
                              <option value={0}>Full Access</option>
                              <option value={1}>View Only</option>
                            </select>
                            {errors?.access_level && touched?.access_level && (
                              <span className="formik-errors">
                                {errors?.access_level}
                              </span>
                            )}
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                            <span>
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn cre_new"
                              >
                                Save
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- container-fluid --> */}
    </div>
  );
}
