import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Allusers from "./pages/All-users";
import UserOverview from "./pages/User-overview";
import Userpurchases from "./pages/User-Purchases";
import Creators from "./pages/Creators";
import Channel from "./pages/Channel";
import ForgotPassword from "./pages/ForgotPassword";
import Transactions from "./pages/Transactions";
import CourseOverview from "./pages/Course-Overview";
import CreateChapter from "./pages/Create-Chapter";
import ChapterOne from "./pages/Chapter-One";
import CreateQuiz from "./pages/Create-Quiz";
import ManageCourses from "./pages/ManageCourses";
import Manageadmin from "./pages/Manage-admin";
import Createadmin from "./pages/Create-admin";
import ChapterOneWithQuiz from "./pages/Chapter-One-with-Quiz";
// import CreateCreator from "./pages/CreateCreator";
import PrivateRoute from "./routes/PrivateRoute";
import { ToastContainer } from "react-toastify";
import CreateCourse from "./components/manageCourses/CreateCourse";
// import SignIn from "./pages/Signin";
import Users from "./pages/Users";
import UserDetails from "./components/userMgmt/UserDetails";
import CreateCreator from "./components/creatorMgmt/CreateCreator";
import CreateChannel from "./components/channelMgmt/CreateChannel";
import Login from "./pages/Login";
import ManageProducts from "./pages/ManageProducts";
import AddProduct from "./pages/AddProduct";
import AddTags from "./pages/AddTags";
import EditAdmin from "./pages/EditAdmins";
import EditCreator from "./components/creatorMgmt/EditCreator";
import EditCourse from "./components/manageCourses/EditCourse";
import EditChapter from "./components/manageCourses/EditChapter";
import EditProduct from "./pages/EditProduct";
import Loader from "./components/Loader";
import ChangePassword from "./pages/ChangePassword";
import EditQuiz from "./pages/EditQuiz";
import Createaccount from "./pages/Createaccount";
import ManageRequests from "./pages/ManageRequests";
import EditChannel from "./components/channelMgmt/EditChannel";
import { useSelector } from "react-redux";
import ProgressBar from "@ramonak/react-progress-bar";
import arrowLeft from "../src/assets/images/arrow-left.svg"


function App() {
  const { uploadProgress, uploadTime, video_title } = useSelector((state) => ({
    uploadProgress: state.course?.uploadProgress?.percent,
    uploadTime: state.course?.uploadProgress?.uploadTime,
    video_title: state.course?.uploadProgress?.video_title,
  }));

  console.log("courseSlice uploadProgress----", uploadProgress)

  const [prog, setProg] = useState(0);
  const [toggle, setToggle] = useState(0);
  const [hide, setHide] = useState();
  const [lastHide, setLastHide] = useState();
  const [uploadSuccess, setUploadSuccess] = useState(1);

  console.log(video_title, "uploadTime----uploadTime");

  useEffect(() => {
    if (uploadProgress) {
      if (uploadProgress == "success") return setLastHide("remove");
      const progressPercentage = uploadProgress;
      console.log(`Upload progress: ${progressPercentage}%`);
      setProg(progressPercentage);
      setToggle(1);
      setHide();
      setUploadSuccess();
    }
  }, [uploadProgress]);

  useEffect(() => {
    if (prog == 100) {
      setUploadSuccess(1);
      setTimeout(() => {
        setHide("hide");
      }, 2000);
    }
  }, [prog]);

  console.log(prog, "prog", hide, "hide")


  return (
    <div className="App">
      {prog != 0 && !hide && (
        <div className={toggle ? "chapter-upload" : "chapter-upload hide"}>
          <h6>{video_title}</h6>
          <button className="close-btn" onClick={() => setToggle(!toggle)}>
            <img src={arrowLeft} />
          </button>
          <div className="chapter-upload-header">
            <h6>
              {uploadSuccess
                ? "Video Uploaded Successfully."
                : "Uploading Video"}
            </h6>

            {/* <button><img src={require("./images/cancelbtn.svg").default}/></button> */}
          </div>

          <ProgressBar
            completed={prog}
            bgColor={uploadSuccess ? "#2b742b" : "black"}
          />
          <p>
            Estimate Time Remaining : <b>{uploadTime}</b>
          </p>
          <h3>{prog?.originalname}</h3>
          {/* {uploadSuccess == 1 && <img src={require("./images/check.png")} />} */}
          {/* {
            hide && <div className="d-block text-center pb-2"> <p>UNDER PROGRESS</p></div>
          } */}
        </div>
      )}

      <ToastContainer limit={1} />
      <Loader />
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<SignIn />} /> */}
          <Route path="/" element={<Login />} />
          <Route path="/createaccount" element={<Createaccount />} />

          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            {/* <Route path="/all-users" element={<Allusers />} /> */}
            <Route path="/users" element={<Users />} />
            <Route path="/ManageCourses" element={<ManageCourses />} />
            <Route path="/creators" element={<Creators />} />

            <Route path="/Manage-admin" element={<Manageadmin />} />
            <Route path="/Create-admin" element={<Createadmin />} />
            <Route path="/editAdmin/:id" element={<EditAdmin />} />

            {/* <Route path="/user-overview/:id" element={<UserOverview />} /> */}
            <Route path="/userDetails/:id" element={<UserDetails />} />

            <Route path="/User-Purchases" element={<Userpurchases />} />

            <Route path="/createCourse" element={<CreateCourse />} />
            <Route path="/editCourse/:id" element={<EditCourse />} />
            <Route path="/channels" element={<Channel />} />
            <Route path="/Transactions" element={<Transactions />} />
            <Route path="/createChannel" element={<CreateChannel />} />
            <Route path="/editChannel/:id" element={<EditChannel />} />
            <Route
              path="/Create-Chapter/:course_id"
              element={<CreateChapter />}
            />
            <Route path="/editChapter/:chapter_id" element={<EditChapter />} />
            <Route path="/Course-Overview/:id" element={<CourseOverview />} />
            <Route
              path="/Create-Quiz/:chapter_id/:course_id"
              element={<CreateQuiz />}
            />
            <Route path="/editQuiz/:id" element={<EditQuiz />} />
            <Route
              path="/Chapter-One-with-Quiz"
              element={<ChapterOneWithQuiz />}
            />
            <Route path="/createCreator" element={<CreateCreator />} />
            <Route path="/editCreator/:id" element={<EditCreator />} />
            <Route path="/ManageProducts" element={<ManageProducts />} />
            <Route path="/AddProduct" element={<AddProduct />} />
            <Route path="/editProduct/:id" element={<EditProduct />} />
            <Route path="/AddTags" element={<AddTags />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
          </Route>

          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/manage-requests" element={<ManageRequests />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
