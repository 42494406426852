import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, requestlist, updatetherequest } from "../redux/Action/adminAction";
import { useState } from "react";
import moment from "moment/moment";
import Search from "../components/search/Search";
import Pagination from "../components/Pagination";

import Layout from "../components/layout/Layout";
export default function ManageRequests() {
  const limit = 8;
  const [page, setPage] = useState();
  const [search, setSearch] = useState();
  const dispatch = useDispatch();
  const requestlistdata = useSelector((state) => state?.adminAction?.requestlistdata);

  useEffect(() => {
    dispatch(requestlist({ page, limit, search }));
  }, [page, limit, search]);






  

  return (
    <Layout>
      <div className="main-content">
        <div className="page-content">
          {/* <!-- start page title --> */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <div>
                  <span className="small_text">Buff Browz</span>
                  <div className="d-flex justify-content-center align-items-center">
                    <h4 className="mb-sm-0 font-size-28">Manage Requests</h4>
                    <p className="total">{requestlistdata.length}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            {/* <!-- start search row --> */}
            <div className="row mb-4">
              {/* <Search setSearch={setSearch} /> */}

              {/* <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
              <div className="form-group">
                <select
                  className="form-control cmn_fields"
                  id="exampleFormControlSelect2"
                >
                  <option>Last Active</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>

            <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
              <div className="form-group">
                <select
                  className="form-control cmn_fields"
                  id="exampleFormControlSelect3"
                >
                  <option>Purchases</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div> */}
            </div>
          </div>
          {/* <!-- end search row --> */}

          <div className="row">
            <div className="col-xl-12">
              <div className="members_tbl height-set">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                      <h4 className="title_text">Request lists</h4>
                    </div>

                    <div className="table-responsive">
                      <table
                        id=""
                        className="table dt-responsive dealers_table nowrap w-100"
                      >
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>First Name</th>
                            <th>Email</th>
                            <th>Date of Birth</th>
                            <th>Mobile Number</th>
                            <th>Address</th>
                            <th>Country </th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody className="td_color">

                        {
                          requestlistdata?.map(function(requestobject,i){
                            return(  <tr>
                            <td>{i+1}</td>
                            <td>{requestobject?.user_id?.full_name}</td>
                            <td>{requestobject?.user_id?.email}</td>
                            <td>{moment(requestobject?.user_id?.dateofbirth).format("DD-MM-YYYY")}</td>
                            <td>{requestobject?.user_id?.phone_number}</td>
                            <td>
                            {requestobject?.user_id?.address}
                            </td>
                            <td> {requestobject?.user_id?.country}</td>
                            <td>
                              <div className="form-check form-switch active-inactive-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  
                              



                                  checked={requestobject?.status==true}
                                  onChange={()=>{
                                    dispatch(updatetherequest({
                                      id:requestobject?._id
                                    }))
                                  }}

                                />
                              </div>
                            </td>
                          </tr>)

                          })
                        }
                        
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <Pagination
                  totalStuff={requestlistdata.length}
                  limit={limit}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- container-fluid --> */}
      </div>
    </Layout>
  );
}
