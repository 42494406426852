import { ErrorMessage, Field, Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  createCourse,
  getStuffForCourseCreation,
} from "../../redux/Action/coursesAction";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

export default function CreateCourse() {
  const navigate = useNavigate();
  const [prevImg, setPrevImg] = useState();
  const dispatch = useDispatch();
  const stuff = useSelector((state) => state.course.stuff);
  const categories = stuff?.categories;
  const creators = stuff?.creators;
  const tags = stuff?.tags;
  const products = stuff?.products;

  useEffect(() => {
    dispatch(getStuffForCourseCreation());
  }, []);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: 500,
    }),
  };


  const handleBlur = (e, setFieldValue) => {
    let value = e.target.value.split('£')[1];
    if (value) {
      value = parseFloat(value).toFixed(2);
      setFieldValue('cost', value);
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid px-0">
          <div className="create_tab">
            <div className="row align-items-center">
              <div className="col-12 d-flex align-items-center">
                <img
                  className="cursor-pointer"
                  onClick={() => navigate(-1)}
                  src={require("../../assets/images/Component.png")}
                />
                <h4 className="mb-sm-0 font-size-28">Create New Course</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="members_tbl">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                      <h4 className="title_text"> Details</h4>
                    </div>

                    <Formik
                      initialValues={{
                        cover_image: "",
                        course_title: "",
                        creator_id: "",
                        coursecategory: "",
                        cost: "",
                        tags: "",
                        course_desc: "",
                        learn: "",
                        products: "",
                      }}
                      validate={(values) => {
                        const errors = {};
                        if (!values?.cover_image) {
                          errors.cover_image = "Required*";
                        }
                        if (!values?.course_title) {
                          errors.course_title = "Required*";
                        }
                        if (!values?.creator_id) {
                          errors.creator_id = "Required*";
                        }
                        if (!values?.coursecategory) {
                          errors.coursecategory = "Required*";
                        }
                        if (!values?.course_desc) {
                          errors.course_desc = "Required*";
                        }
                        if (!values?.learn) {
                          errors.learn = "Required*";
                        }
                        if (!values?.products) {
                          errors.products = "Required*";
                        }
                        if (!values?.tags) {
                          errors.tags = "Required*";
                        }

                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        console.log("values-----", values)
                        const formdata = new FormData();
                        formdata.append("cover_image", values?.cover_image);
                        formdata.append("course_title", values?.course_title);
                        formdata.append("creator_id", values?.creator_id?.value);
                        formdata.append("coursecategory", values?.coursecategory?.value);
                        formdata.append("cost", values?.cost ? parseFloat(values?.cost).toFixed(2) : 0);
                        formdata.append("course_desc", values?.course_desc);
                        formdata.append("learn", values?.learn);
                        formdata.append("products", JSON.stringify(values?.products?.map((pro) => pro?.value)));
                        formdata.append("tags", JSON.stringify(values?.tags?.map((tag) => tag.value)));

                        dispatch(createCourse(formdata)).then((response) => {
                          if (response?.payload?.success) {
                            toast.success(response?.payload?.message);
                            navigate(`/Course-Overview/${response?.payload?.data?._id}`);
                          } else if (!response?.payload?.success) {
                            toast.warning(response?.payload?.message);
                          }
                        });
                      }}
                    >
                      {({ values, isSubmitting, setFieldValue }) => (
                        <Form>
                          <div className="course-overview-main creating">
                            <div className="row">
                              <div className="col-xxl-12 col-xl-12 col-lg-12">
                                <div className="row">
                                  <div className="col-lg-2">
                                    <label>Course Cover Image</label>
                                  </div>
                                  <div className="col-lg-10 d-flex align-items-end flex-column">
                                    <div className="img_uploaded_box cover-img">
                                      <img src={prevImg} alt="" />
                                    </div>

                                    <div className="up_m_btn mt-3">
                                      <input
                                        type="file"
                                        class="upload_field"
                                        placeholder="Upload Image"
                                        onChange={(e) => {
                                          e?.target?.files[0] &&
                                            setFieldValue("cover_image", e?.target?.files[0]);
                                          e?.target?.files[0] &&
                                            setPrevImg(URL.createObjectURL(e?.target?.files[0]));
                                        }}
                                      />

                                      <p className="upload_btn cursor-pointer">
                                        Upload Image
                                      </p>
                                    </div>
                                    <div className="error-msg">
                                      <ErrorMessage name="cover_image" component="div" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-12 col-xl-12 col-lg-12 mt-2">
                                <div className="row h-100 align-content-end">
                                  <div className="col-lg-2">
                                    <label className="set_custom">Cost</label>
                                  </div>
                                  <div className="col-lg-10">
                                  <NumericFormat
                                      className="form-control margin"
                                      placeholder="£ -.--"
                                      value={values?.cost}
                                      prefix="£"
                                      thousandSeparator=","
                                      onBlur={(e) => handleBlur(e, setFieldValue)}
                                      onChange={({ target: { value } }) =>
                                        setFieldValue("cost", value)
                                      }
                                    />

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4 mb-4">
                              <div className="col-lg-2">
                                <label>Course Title</label>
                              </div>
                              <div className="col-lg-10">
                                <Field
                                  class="form-control"
                                  type="text"
                                  name="course_title"
                                  placeholder="Type Here.."
                                />
                                <div className="error-msg">
                                  <ErrorMessage name="course_title" component="div" />
                                </div>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-lg-6">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <label>Category</label>
                                  </div>
                                  <div className="col-lg-8">
                                    <Select
                                      className="form-control"
                                      value={values?.coursecategory}
                                      onChange={(e) => setFieldValue("coursecategory", e)}
                                      options={categories}
                                      styles={customStyles}
                                    />
                                    <div className="error-msg">
                                      <ErrorMessage name="coursecategory" component="div" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <label>Creator</label>
                                  </div>
                                  <div className="col-lg-8">
                                    <Select
                                      className="form-control"
                                      value={values?.creator_id}
                                      onChange={(e) => setFieldValue("creator_id", e)}
                                      options={creators}
                                      styles={customStyles}
                                    />
                                    <div className="error-msg">
                                      <ErrorMessage name="creator_id" component="div" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-lg-2">
                                <label>Course Description</label>
                              </div>
                              <div className="col-lg-10">
                                <Field
                                  name="course_desc"
                                  as="textarea"
                                  class="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                  placeholder="Type Here..."
                                />
                                <div className="error-msg">
                                  <ErrorMessage name="course_desc" component="div" />
                                </div>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-lg-2">
                                <label>What You’ll Learn</label>
                              </div>
                              <div className="col-lg-10">
                                <Field
                                  name="learn"
                                  as="textarea"
                                  class="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                  placeholder="Type Here..."
                                />
                                <div className="error-msg">
                                  <ErrorMessage name="learn" component="div" />
                                </div>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-lg-2">
                                <label>Products Used</label>
                              </div>
                              <div className="col-lg-10">
                                <Select
                                  className="form-control"
                                  value={values?.products}
                                  isMulti={true}
                                  onChange={(e) => setFieldValue("products", e)}
                                  options={products}
                                  styles={customStyles}
                                />
                                <div className="error-msg">
                                  <ErrorMessage name="products" component="div" />
                                </div>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-lg-2">
                                <label>Add Tags</label>
                              </div>
                              <div className="col-lg-10">
                                <Select
                                  className="form-control"
                                  value={values?.tags}
                                  isMulti={true}
                                  onChange={(e) => setFieldValue("tags", e)}
                                  options={tags}
                                  styles={customStyles}
                                />
                              </div>
                              <div className="error-msg">
                                <ErrorMessage name="tags" component="div" />
                              </div>
                            </div>
                            <div className="col-lg-12 d-flex justify-content-end">
                              <button className="create_cmn new c_create mt-0" type="submit">
                                Create Course
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
