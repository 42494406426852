import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { createQuiz, getCourseDetails } from "../redux/Action/coursesAction";
import { toast } from "react-toastify";

export default function CreateQuiz() {
  const questionObj = {
    questiontitle: "",
    option: [{ optiontitle: "", answer: false }],
    answer: "",
  };

  const navigate = useNavigate();
  const [questions, setQuestions] = useState([questionObj]);
  const { chapter_id, course_id } = useParams();
  const dispatch = useDispatch();
  const courseDetails = useSelector((state) => state?.course?.courseDetails);

  useEffect(() => {
    dispatch(getCourseDetails(course_id));
  }, [dispatch, course_id]);

  const handleAllChange = (params) => {
    console.log("hnadleChange params", params)
    setQuestions((prev) => {
      params.changeThis = [...prev];
      return SwitchFunc(params);
    });
  };

  const SwitchFunc = (params) => {
    console.log("swtichFunc params", params)
    const { key, change, e, question_index, option_index, changeThis } = params;

    switch (key) {
      case "changeQuestionTitle":
        changeThis[question_index][change] = e.target.value;
        return changeThis;

      case "changeOptionTitle":
        changeThis[question_index].option[option_index][change] = e.target.value;
        return changeThis;

      case "deleteQuestion":
        changeThis.splice(question_index, 1);
        return changeThis;

      case "addQuestion":
        changeThis.push(questionObj);
        return changeThis;

      case "addOption":
        changeThis[question_index].option?.length < 4 &&
          changeThis[question_index].option.push({ optiontitle: "", answer: false });
        return changeThis;

      case "deleteOption":
        if (changeThis[question_index].answer == option_index) {
          changeThis[question_index].answer = "";
        }
        changeThis[question_index].option.splice(option_index, 1);
        return changeThis;

      case "correctAns":
        changeThis[question_index].option.forEach((opt, idx) => {
          opt.answer = idx === option_index;
        });
        return changeThis;

      default:
        break;
    }
  };

  const handleSubmit = async () => {
    let hasError = false;

    questions.forEach((question, question_index) => {
      if (!question.questiontitle) {
        toast.warning(`Question title for question ${question_index + 1} is missing`);
        hasError = true;
      } else if (question.option.some((option) => !option.optiontitle) || question.option.length < 2) {
        toast.warning(`Options for question ${question_index + 1} are missing`);
        hasError = true;
      } else if (question.option.every((option) => !option.answer)) {
        toast.warning(`Answer for question ${question_index + 1} is missing`);
        hasError = true;
      }
    });

    if (hasError) return;

    // Create a deep copy of questions to avoid mutating the state directly
    const sanitizedQuestions = questions.map((question) => {
      const sanitizedQuestion = { ...question };

      if (!sanitizedQuestion._id) {
        delete sanitizedQuestion._id;
      }

      sanitizedQuestion.option = sanitizedQuestion.option.map((opt) => {
        const sanitizedOption = { ...opt };
        if (!sanitizedOption._id) {
          delete sanitizedOption._id;
        }
        return sanitizedOption;
      });

      return sanitizedQuestion;
    });

    const quizObj = {
      creator_id: courseDetails?.creator_id?._id,
      chapter_id: chapter_id,
      question: sanitizedQuestions,
    };

    console.log("payload", quizObj)

    try {

      const response = await dispatch(createQuiz(quizObj));
      if (response?.payload?.success) {
        toast.success(response?.payload?.message);
        navigate(`/Course-Overview/${course_id}?chapter_id=${chapter_id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid px-0">
          <div className="create_tab">
            {/* <!-- start page title --> */}
            <div className="row align-items-center">
              <div className="col-6 d-flex align-items-center">
                <Link className="back_img" to="/ManageCourses">
                  <img src={require("../assets/images/Expand_left.svg").default} alt="Back" />
                </Link>
                <h4 className="mb-sm-0 font-size-28">
                  Course Introduction - Quiz
                </h4>
              </div>
              <div className="col-6 d-flex justify-content-end">
                {/* <p className="sub_heading">Joined: 26/08/2021</p> */}
              </div>
            </div>
          </div>
          {/* <!-- end page title --> */}
          <div className="row mb-4">
            <div className="col-lg-12 d-flex justify-content-end">
              <button
                onClick={handleSubmit}
                className="btn cre_new"
                disabled={courseDetails?.creator_id ? false : true}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="members_tbl create_quiz">
                {questions?.map((question, question_index) => {
                  return (
                    <div className="card mb-4" key={question_index}>
                      <div className="card-body ">
                        <div className="d-flex justify-content-start align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">
                            Question {question_index + 1}
                          </h4>
                        </div>
                        <div className="contact_details pb-2">
                          <div className="form_fields_main mb-3">
                            <label>Question Title</label>
                            <input
                              type="text"
                              className="form-control main-question"
                              placeholder="Type Here.."
                              onChange={(e) =>
                                handleAllChange({
                                  key: "changeQuestionTitle",
                                  change: "questiontitle",
                                  e,
                                  question_index,
                                })
                              }
                              value={question?.questiontitle}
                            />
                          </div>

                          <div className="form_fields_extra mt-4">
                            <label>Set Answers</label>
                            <div className="extra_bottom d-flex align-items-top">
                              <div>
                                <ol>
                                  <li className="justify-content-end correct-heading">
                                    Correct
                                  </li>
                                  {question?.option?.map(
                                    (option, option_index) => {
                                      return (
                                        <React.Fragment key={option_index}>
                                          <li>
                                            <span>{option_index + 1}</span>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Type Here.."
                                              onChange={(e) =>
                                                handleAllChange({
                                                  key: "changeOptionTitle",
                                                  change: "optiontitle",
                                                  e,
                                                  question_index,
                                                  option_index,
                                                })
                                              }
                                              value={option?.optiontitle}
                                            />
                                            <input
                                              onClick={() =>
                                                handleAllChange({
                                                  key: "correctAns",
                                                  option_index,
                                                  question_index,
                                                })
                                              }
                                              className="form-check-input check-custom"
                                              type="radio"
                                              name={`radio${question_index}`}
                                              checked={option.answer}
                                              readOnly
                                            />
                                            <button
                                              type="button"
                                              className="close-img"
                                              onClick={() =>
                                                handleAllChange({
                                                  key: "deleteOption",
                                                  option_index,
                                                  question_index,
                                                })
                                              }
                                            >
                                              <img
                                                src={require("../assets/images/Close_square_fill.png")}
                                                alt="Delete"
                                              />
                                            </button>
                                          </li>
                                          <br />
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                  <li className="justify-content-end add-ansers">
                                    <img
                                      className="add-question"
                                      onClick={() =>
                                        handleAllChange({
                                          key: "addOption",
                                          question_index,
                                        })
                                      }
                                      src={
                                        require("../assets/images/plus.svg")
                                          .default
                                      }
                                      alt="Add Option"
                                    />
                                  </li>
                                </ol>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 d-flex justify-content-end">
                            <button
                              onClick={() =>
                                handleAllChange({
                                  key: "deleteQuestion",
                                  question_index,
                                })
                              }
                              className="btn delete_one"
                              type="button"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    onClick={() => handleAllChange({ key: "addQuestion" })}
                    className="btn cre_new_one add"
                  >
                    Add Question
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- container-fluid --> */}
      </div>
      {/* <!-- End Page-content --> */}
    </div>
  );
}
