import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChapterDetails } from "../../redux/Action/coursesAction";
import { Link, useParams } from "react-router-dom";
import Quiz from "./Quiz";
import userPlaceholder from "../../assets/images/usericon.svg";
import CommonModal from "../Modals/CommonModal";


const Chapter = ({ chapterId, setChapterId }) => {
  console.log("chpaterId---", chapterId)
  const BASE_URL = process.env.REACT_APP_IMAGE_URL;
  const dispatch = useDispatch();
  const chapterDetails = useSelector((state) => state.course.chapterDetails);
  const [type, setType] = useState("");
  const [show, setShow] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    dispatch(getChapterDetails(chapterId));
  }, [chapterId]);

  console.log(chapterDetails, "chapterDetails");
  return (
    <>
      <div className="container-fluid px-0">
        {type && chapterId && (
          <CommonModal show={show} setShow={setShow} type={type} id={chapterId} setChapterId={setChapterId} />
        )}

        <div className="row">
          <div className="col-xl-12">
            <div className="members_tbl">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                    <h4 className="title_text"> Details</h4>
                    <div>
                      <button className="btn cre_new_one delete" type="button" onClick={() => {
                        setType("deleteChapter")
                        setShow(true)
                      }} >Delete</button>

                      <Link to={`/editChapter/${chapterDetails?._id}`}>
                        <button className="btn cre_new_one">Edit</button>
                      </Link>
                      {!chapterDetails?.quiz && (
                        <Link to={`/Create-Quiz/${chapterId}/${id}`}>
                          <button className="btn cre_new_one">
                            Create Quiz
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="contact_details create_channel create_course chap_one">
                    <form>
                      <div className="form_fields_main mb-4">
                        <label>Chapter Cover Image</label>

                        <div className="d-flex align-items-end">
                          <div className="img_uploaded_box">
                            <img
                              src={(chapterDetails?.chapter_cover_image || chapterDetails?.cover_image) ?? userPlaceholder}
                            />
                          </div>
                          <div className="up_m_btn" style={{ opacity: 0 }}>
                            <input
                              type="file"
                              class="upload_field"
                              id=""
                              placeholder="Upload Image"
                            />
                            <p className="upload_btn">Upload Image</p>
                          </div>
                        </div>
                      </div>

                      <div className="form_fields_main mb-4">
                        <label>Chapter Title</label>
                        <input
                          type="text"
                          class="form-control"
                          id=""
                          placeholder=""
                          value={chapterDetails?.chapter_title}
                        />
                      </div>

                      <div className="form_fields_main mb-4">
                        <label>Upload Video</label>
                        {
                          chapterDetails?.video ?
                            <div className="upload_progress">
                              <p>Video Upload Successful</p>
                            </div>
                            : <div className="up_m_btn">
                              <input
                                type="file"
                                class="upload_field"
                                id=""
                                placeholder="Upload Image"
                              />
                              <p className="upload_btn">Upload</p>
                            </div>
                        }
                      </div>

                      <div className="form_fields_main mb-4">
                        <label>Course Description</label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          value={chapterDetails?.chapter_desc}
                        />
                      </div>
                      <div className="form_fields_main mb-4">
                        <label>What You’ll Learn</label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          value={chapterDetails?.learn}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {chapterDetails?.quiz &&

        <Quiz quiz={chapterDetails?.quiz} />

      }
    </>
  );
};

export default Chapter;
