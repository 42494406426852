import { createSlice } from "@reduxjs/toolkit";
import {
  deleteAdmin,
  getAdminDetails,
  getAllManageAdmins,
  getAllUsers,
  getUserDetail,
  requestlist,
  updatetherequest,
} from "../Action/adminAction";
import { toast } from "react-toastify";
import { approveuserprofile, getUserPurchase } from "../Action/userAction";

const initialState = {
  users: null,
  userDetail: null,
  userPurchase: {},
  admins: null,
  totalAdmins: null,
  adminDetails: null,
  requestlistdata: []
};
const adminSlice = createSlice({
  name: "adminPanel",
  initialState,
  reducers: {
    //   pushNeedAssist: (state, { payload }) => {
    //   },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.users = payload?.data?.users;
          state.totalUsers = payload?.data?.totalUsers;
        }
      })
      .addCase(approveuserprofile.fulfilled, (state, { payload }) => {
        if (payload?.success) {

          var userup = payload?.data;

          const updatedUsers = state?.users?.map(user => {
            // Assuming you want to update a property (e.g., set approved to true)
            if (user._id === payload?.data._id) {
              return {
                ...userup

              };
            }
            return user;
          });
          state.users = updatedUsers;
        }
      })

      .addCase(getUserDetail.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.userDetail = payload?.data;
        } else {
          toast.error(payload?.message);
        }
      })
      .addCase(getUserPurchase.fulfilled, (state, { payload }) => {
        console.log("payload", payload)
        if (payload?.success) {
          state.userPurchase = payload.data;
        } else {
          toast.error(payload?.message);
        }
      })
      .addCase(requestlist.fulfilled, (state, { payload }) => {
        if (payload?.success) {

          state.requestlistdata = payload?.data;
        } else {
          toast.error(payload?.message);
        }
      })


      .addCase(updatetherequest.fulfilled, (state, { payload }) => {
        if (payload?.success) {

          var requestobject = payload?.data;
          const updatedUsers = state.requestlistdata.map(object => {
            if (object._id === payload?.data._id) {
              return {
                ...requestobject

              };
            }
            return object;
          });




          state.requestlistdata = updatedUsers;






        }
      })




      .addCase(getAllManageAdmins.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.admins = payload?.data?.users;
          state.totalAdmins = payload?.data?.totalUsers;
        }
      })
      .addCase(deleteAdmin.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.admins = state.admins?.filter(
            (admin) => admin?._id != payload?.data?._id
          );
          state.totalAdmins = state.totalAdmins - 1;
          toast.success(payload?.message);
        } else {
          toast.error(payload?.message);
        }
      })
      .addCase(getAdminDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.adminDetails = payload?.data;
        } else {
          toast.error(payload?.message);
        }
      });
  },
});
export default adminSlice.reducer;
