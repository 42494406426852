import { createAsyncThunk } from "@reduxjs/toolkit";
import { startstopLoading } from "../Reducer/loaderSlice";
import API from "../../services/Api";

const getAllTransaction = createAsyncThunk(
    "getAllTransaction",
    async ({ page, limit, search }, { dispatch }) => {
        dispatch(startstopLoading(true));
        const { data } = await API.get(
            `/getAllTransactions?page=${page}&limit=${limit}&search=${search || ""}`
        );
        dispatch(startstopLoading(false));
        return data;
    }
);

export {
    getAllTransaction
}