const formatCurrency = (number, currencySymbol) => {
    return `${currencySymbol || ""}${number?.toFixed(2)}`;
};

const formatPrice = (value) => {
    const number = parseFloat(value);
    if (isNaN(number)) {
      return '0.00';
    }
    return number.toFixed(2);
  };

export { formatCurrency, formatPrice }