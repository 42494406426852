import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Sidebar from "./Include/Sidebar";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../redux/Action/productsAction";
import Pagination from "../components/Pagination";
import CommonModal from "../components/Modals/CommonModal";

export default function ManageProducts() {
  const limit = 8;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [id, setId] = useState();
  const [type, setType] = useState();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);
  const totalProducts = useSelector((state) => state.product.totalProducts);

  useEffect(() => {
    dispatch(getProducts({ page, limit, search }));
  }, [page, search]);

  return (
    <div class="main-content">
      <div class="page-content">
        {/* <!-- start page title --> */}
        {type && id && (
          <CommonModal show={show} setShow={setShow} type={type} id={id} />
        )}
        <div class="row">
          <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
              <div>
                <span class="small_text">Buff Browz</span>
                <div className="d-flex justify-content-center align-items-center">
                  <h4 class="mb-sm-0 font-size-28">All Products </h4>
                  <p className="total">{totalProducts}</p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end page title --> */}

          {/* <!-- start search row --> */}
          <div class="row mb-4">
            <div class="col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-6">
              <input
                type="text"
                class="form-control cmn_fields"
                id=""
                aria-describedby="emailHelp"
                placeholder="Search Here"
              />
            </div>

            <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-6 col-6 d-flex justify-content-end">
              <span>
                <NavLink to="/AddProduct">
                  {" "}
                  <button class="btn create_cmn new">Create New</button>
                </NavLink>
              </span>
            </div>
          </div>
        </div>
        {/* <!-- end search row --> */}

        <div class="row">
          <div class="col-xl-12">
            <div class="members_tbl height-set">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                    <h4 class="title_text">Products</h4>
                  </div>

                  <div class="table-responsive">
                    <table
                      id=""
                      class="table dt-responsive dealers_table nowrap w-100"
                    >
                      <thead>
                        <tr>
                          <th>Product ID</th>
                          <th>Product Name</th>
                          <th>Manufacturer</th>
                          <th>Price £</th>

                          <th>Has Images</th>
                          <th>Has Product Link</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody class="td_color">
                        {products?.length > 0 &&
                          products?.map((product) => {
                            return (
                              <tr>
                                <td>
                                  {product?.product_no
                                    ?.toString()
                                    ?.padStart(3, "0")}
                                </td>
                                <td>{product?.name}</td>
                                <td>{product?.manufacturer_name}</td>
                                <td>£{product?.price}</td>
                                <td>{product?.product1 ? "Yes" : "No"}</td>
                                <td>{product?.link ? "Yes" : "No"}</td>
                                <td className="d-flex">
                                  <Link to={`/editProduct/${product?._id}`}>
                                    <button className="button edit">
                                      Edit
                                    </button>
                                  </Link>
                                  <button
                                    onClick={() => {
                                      setShow(true);
                                      setId(product?._id);
                                      setType("deleteProduct");
                                    }}
                                    className="button delete"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {/* <div className="no-data-div">
                        <p className="no-data">No data Found</p>
                      </div> */}
                  </div>
                </div>
              </div>
              <Pagination
                totalStuff={totalProducts}
                limit={limit}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- container-fluid --> */}
    </div>
  );
}
