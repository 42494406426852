import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../services/Api";
import { startstopLoading } from "../Reducer/loaderSlice";

const createCreator = createAsyncThunk(
  "createCreator",
  async (formdata, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.post("/createCreator", formdata);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const getCreators = createAsyncThunk(
  "getCreators",
  async ({ page, limit, search }, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(
      `/getCreators?page=${page}&limit=${limit}&search=${search}`
    );
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const getCreatorDetails = createAsyncThunk(
  "getCreatorDetails",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(`/getCreatorDetails?id=${id}`);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const editCreator = createAsyncThunk(
  "editCreator",
  async (formdata, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.put("/editCreator", formdata);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const deleteCreator = createAsyncThunk("deleteCreator", async (id) => {
  const { data } = await API.delete(`/deleteCreator?id=${id}`);
  return data;
});

export {
  createCreator,
  getCreators,
  getCreatorDetails,
  editCreator,
  deleteCreator,
};
