import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../services/Api";
import { startstopLoading } from "../Reducer/loaderSlice";

const getProducts = createAsyncThunk(
  "getProducts",
  async ({ page, limit, search }, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(
      `/getProducts?page=${page}&limit=${limit}&search=${search}`
    );
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const createProduct = createAsyncThunk(
  "createProduct",
  async (formdata, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.post("/createProduct", formdata);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const editProduct = createAsyncThunk(
  "editProduct",
  async (formdata, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.put("/editProduct", formdata);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const getProductDetails = createAsyncThunk(
  "getProductDetails",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(`/getProductDetails?id=${id}`);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const deleteProduct = createAsyncThunk(
  "deleteProduct",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.delete(`/deleteProduct?id=${id}`);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

export {
  createProduct,
  getProducts,
  getProductDetails,
  editProduct,
  deleteProduct,
};
