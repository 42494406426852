import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  deleteCourse,
  getChapterDetails,
  getChapters,
  getCourseDetails,
  getCourses,
  getQuizDetails,
  getStuffForCourseCreation,
  setUploadProgress,
} from "../Action/coursesAction";

const initialState = {
  stuff: null,
  courses: null,
  totalCourses: null,
  courseDetails: null,
  progressBar: 0,
  chapters: null,
  chapterDetails: null,
  quizDetails: null,
  uploadProgress:0,
};

const courseSlice = createSlice({
  name: "courseSlice",
  initialState,
  reducers: {
    updateProgressBar(state, { payload }) {
      state.progressBar = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStuffForCourseCreation.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.stuff = payload?.data;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(getCourses.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.courses = payload?.data?.courses;
          state.totalCourses = payload?.data?.totalCourses;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(deleteCourse.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.courses = state.courses?.filter(
            (course) => course?._id != payload?.data?._id
          );
          state.totalCourses = state.totalCourses - 1;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(getCourseDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.courseDetails = payload?.data;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(getChapters.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.chapters = payload?.data;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(getChapterDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.chapterDetails = payload?.data;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(getQuizDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.quizDetails = payload?.data;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(setUploadProgress.fulfilled, (state, { payload }) => {
        state.uploadProgress = payload;
      });
  },
});

export const { updateProgressBar } = courseSlice.actions;

export default courseSlice.reducer;
