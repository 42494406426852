import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const SideBar = () => {
  const navigate = useNavigate();
  const handleLogOut = () => {
    sessionStorage.removeItem("adminToken");
    navigate("/");
  };

  const { pathname } = useLocation();

  const isCurrentPage = (...tabs) => {
    return tabs.some((tab) => pathname.includes(tab));
  };

  return (
    <div id="sidebar-menu">
      <div className="navbar-brand-box">
        <Link to="/dashboard" className="logo logo-dark">
          <span className="logo-lg">
            <img
              src={
                require("../../assets/images/BUFF Education-Logo(1).svg")
                  .default
              }
            />
          </span>
        </Link>
      </div>
      {/* <!-- Left Menu Start --> */}

      <ul className="metismenu list-unstyled" id="side-menu">
        <hr />
        <li className={isCurrentPage("dashboard") && "bar_active"}>
          <Link to="/dashboard" className="waves-effect">
            <i>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_82_4735)">
                  <path
                    d="M9.99998 19V14H14V19C14 19.55 14.45 20 15 20H18C18.55 20 19 19.55 19 19V12H20.7C21.16 12 21.38 11.43 21.03 11.13L12.67 3.6C12.29 3.26 11.71 3.26 11.33 3.6L2.96998 11.13C2.62998 11.43 2.83998 12 3.29998 12H4.99998V19C4.99998 19.55 5.44998 20 5.99998 20H8.99998C9.54998 20 9.99998 19.55 9.99998 19Z"
                    fill="#A3AED0"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_82_4735">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            <span key="t-dashboards">Dashboard</span>
          </Link>
        </li>
        <hr />

        <li className={isCurrentPage("users", "userDetails") && "bar_active"}>
          <Link to="/users" className="waves-effect">
            <i className="">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="10"
                  r="3"
                  stroke="#A3AED0"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <circle
                  cx="12"
                  cy="12"
                  r="9"
                  stroke="#A3AED0"
                  stroke-width="2"
                />
                <path
                  d="M17.7805 18.8264C17.9076 18.7566 17.9678 18.6055 17.914 18.4708C17.5284 17.5045 16.7856 16.6534 15.7814 16.0332C14.6966 15.3632 13.3674 15 12 15C10.6326 15 9.30341 15.3632 8.21858 16.0332C7.21444 16.6534 6.4716 17.5045 6.08598 18.4708C6.03223 18.6055 6.09236 18.7566 6.21948 18.8264C9.81971 20.803 14.1803 20.803 17.7805 18.8264Z"
                  fill="#A3AED0"
                />
              </svg>
            </i>
            <span key="t-dashboards">Users</span>
          </Link>
        </li>

        <li
          className={
            isCurrentPage("creators", "createCreator", "editCreator") &&
            "bar_active"
          }
        >
          <Link to="/creators" className="waves-effect">
            <i className="">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="10"
                  r="3"
                  stroke="#A3AED0"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <circle
                  cx="12"
                  cy="12"
                  r="9"
                  stroke="#A3AED0"
                  stroke-width="2"
                />
                <path
                  d="M17.7805 18.8264C17.9076 18.7566 17.9678 18.6055 17.914 18.4708C17.5284 17.5045 16.7856 16.6534 15.7814 16.0332C14.6966 15.3632 13.3674 15 12 15C10.6326 15 9.30341 15.3632 8.21858 16.0332C7.21444 16.6534 6.4716 17.5045 6.08598 18.4708C6.03223 18.6055 6.09236 18.7566 6.21948 18.8264C9.81971 20.803 14.1803 20.803 17.7805 18.8264Z"
                  fill="#A3AED0"
                />
              </svg>
            </i>
            <span key="t-dashboards">Creators</span>
          </Link>
        </li>

        <li
          className={isCurrentPage("channels", "createChannel") && "bar_active"}
        >
          <Link to="/channels" className="waves-effect">
            <i className="">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                  fill="#A3AED0"
                />
              </svg>
            </i>
            <span key="t-dashboards">Channels</span>
          </Link>
        </li>

        <hr />
        <li
          className={
            window.location.pathname == "/Transactions" ? "bar_active" : ""
          }
        >
          <Link to="/Transactions" className="waves-effect">
            <i className="">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                  fill="#A3AED0"
                />
              </svg>
            </i>
            <span key="t-dashboards">Transactions</span>
          </Link>
        </li>
        <hr />
        <li
          className={
            isCurrentPage(
              "manage-requests",
              // "CreateCourse",
              // "Create-Chapter",
              // "Course-Overview",
              // "editChapter",
              // "Create-Quiz",
              // "editCourse"
            ) && "bar_active"
          }
        >
          <Link to="/manage-requests" className="waves-effect">
            <i className="">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                  fill="#A3AED0"
                />
              </svg>
            </i>
            <span key="t-dashboards">Manage Requests</span>
          </Link>
        </li>
        <hr />
        <li
          className={
            isCurrentPage(
              "ManageCourses",
              "CreateCourse",
              "Create-Chapter",
              "Course-Overview",
              "editChapter",
              "Create-Quiz",
              "editCourse",
              "editQuiz"
            ) && "bar_active"
          }
        >
          <Link to="/ManageCourses" className="waves-effect">
            <i className="">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                  fill="#A3AED0"
                />
              </svg>
            </i>
            <span key="t-dashboards">Manage Courses</span>
          </Link>
        </li>
        <hr />
        <li
          className={
            window.location.pathname == "/ManageProducts" ? "bar_active" : ""
          }
        >
          <Link to="/ManageProducts" className="waves-effect">
            <i className="">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                  fill="#A3AED0"
                />
              </svg>
            </i>
            <span key="t-dashboards">Manage Products</span>
          </Link>
        </li>
        <hr />
        <li className={isCurrentPage("AddTags") && "bar_active"}>
          <Link to="/AddTags" className="waves-effect">
            <i className="">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                  fill="#A3AED0"
                />
              </svg>
            </i>
            <span key="t-dashboards">Add Tags</span>
          </Link>
        </li>
        <div className="last_menu">
          <hr />

          <li
            className={
              window.location.pathname == "/Manage-admin" ? "bar_active" : ""
            }
          >
            <Link to="/Manage-admin" className="waves-effect">
              <i className="">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                    fill="#A3AED0"
                  />
                </svg>
              </i>
              <span key="t-dashboards">Manage Admin</span>
            </Link>
          </li>
          <hr />
          <li className={window.location.pathname == "/" ? "bar_active" : ""}>
            <a onClick={() => handleLogOut()} className="waves-effect">
              <i className="">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                    fill="#A3AED0"
                  />
                </svg>
              </i>
              <span key="t-dashboards">Log Out</span>
            </a>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default SideBar;
