import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../services/Api";
import { startstopLoading } from "../Reducer/loaderSlice";

const createChannel = createAsyncThunk(
  "createChannel",
  async (formdata, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.post("/createChannel", formdata);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const getChannels = createAsyncThunk(
  "getChannels",
  async ({ page, limit, search }, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(
      `/getChannels?page=${page}&limit=${limit}&search=${search}`
    );
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const getChannelDetails = createAsyncThunk(
  "getChannelDetails",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(`/channels/${id}`);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const editChannel = createAsyncThunk(
  "editChannel",
  async (formdata, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.put("/edit-channel", formdata);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const deleteChannel = createAsyncThunk("deleteChannel", async (id) => {
  const { data } = await API.delete(`/deleteChannel/${id}`);
  return data;
});


export { createChannel, getChannels, getChannelDetails, editChannel, deleteChannel };
