import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { editProduct, getProductDetails } from "../redux/Action/productsAction";
import { toast } from "react-toastify";

const EditProduct = () => {
  const BASE_URL = process.env.REACT_APP_IMAGE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const productDetails = useSelector((state) => state.product.productDetails);
  const [prevImg, setPrevImg] = useState({
    product1: "",
    product2: "",
    product3: "",
    product4: "",
  });

  const imageKeyArr = ["product1", "product2", "product3", "product4"];

  useEffect(() => {
    dispatch(getProductDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (productDetails) {
      setPrevImg(() => {
        let imgObj = {};
        imageKeyArr.forEach((key) => {
          imgObj[key] = productDetails[key] ? productDetails[key] : "";
        });
        return imgObj;
      });
    }
  }, [productDetails]);

  const handleImageUpload = (e, key, setFieldValue) => {
    e.target.files[0] && setFieldValue(key, e.target.files[0]);
    e.target.files[0] &&
      setPrevImg((prev) => ({
        ...prev,
        [key]: URL.createObjectURL(e.target.files[0]),
      }));
  };

  const handleImageDelete = (key, setFieldValue) => {
    setFieldValue(key, ""); // Clear the form field
    setPrevImg((prev) => ({
      ...prev,
      [key]: "", // Clear the preview image
    }));
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid px-0">
          <div className="create_tab">
            <div className="row align-items-center">
              <div className="col-12 d-flex align-items-center">
                <Link to="/ManageProducts" className="d-flex">
                  <img src={require("../assets/images/Component.png")} alt="Component Icon" />
                  <h4 className="mb-sm-0 font-size-28">Edit Product</h4>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="members_tbl">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                      <h4 className="title_text">Details</h4>
                    </div>
                    <div className="contact_details product-add">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          product1: productDetails?.product1 || "",
                          product2: productDetails?.product2 || "",
                          product3: productDetails?.product3 || "",
                          product4: productDetails?.product4 || "",
                          name: productDetails?.name || "",
                          manufacturer_name: productDetails?.manufacturer_name || "",
                          desc: productDetails?.desc || "",
                          link: productDetails?.link || "",
                          price: productDetails?.price || "",
                        }}
                        validate={(values) => {
                          const errors = {};
                          ["name", "manufacturer_name", "desc", "price"].forEach((key) => {
                            if (!values[key]) {
                              errors[key] = "Required*";
                            }
                          });
                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          let formData = new FormData();
                          [
                            "product1",
                            "product2",
                            "product3",
                            "product4",
                            "name",
                            "manufacturer_name",
                            "desc",
                            "link",
                            "price",
                          ].forEach((key) => {
                            if(key == "product1" || key == "product2" || key == "product3" || key == "product4"){
                              formData.append(key, values[key]);
                            } else if (values[key] && values[key] !== "") {
                              formData.append(key, values[key]);
                            }
                          });

                          formData.append("id", id);

                          dispatch(editProduct(formData))
                            .then((response) => {
                              if (response.payload.success) {
                                navigate("/ManageProducts");
                                toast.success(response.payload.message);
                              }
                            })
                            .catch((error) => toast.error(error.message));
                          setSubmitting(false);
                        }}
                      >
                        {({ isSubmitting, setFieldValue, values }) => (
                          <Form>
                            <div className="form_fields_main upload mb-4">
                              <label>Product Images</label>
                              <div className="row">
                                <div className="col-lg-6"></div>
                              </div>
                              <div className="d-flex align-items-end">
                                {imageKeyArr.map((key) => (
                                  <div className="img_uploaded_box" key={key}>
                                    {prevImg[key] && (
                                      <button
                                        type="button"
                                        onClick={() => handleImageDelete(key, setFieldValue)}
                                      >
                                        <img src={require("../assets/images/Close_square_fill.png")} alt="Delete Icon" />
                                      </button>
                                    )}
                                    <div className="up_m_btn-product">
                                      <p>Upload Image</p>
                                      <input
                                        type="file"
                                        className="upload_field w-100"
                                        placeholder="Upload Image"
                                        onChange={(e) => handleImageUpload(e, key, setFieldValue)}
                                      />
                                    </div>
                                    {prevImg[key] && (
                                      <img className="show-img" src={prevImg[key]} alt={`Preview ${key}`} />
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="form_fields_main mb-4">
                              <label>Product Name</label>
                              <Field
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                              />
                              <ErrorMessage name="name" component="div" />
                            </div>

                            <div className="form_fields_main mb-4">
                              <label>Manufacturer Name</label>
                              <Field
                                name="manufacturer_name"
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                              />
                              <ErrorMessage name="manufacturer_name" component="div" />
                            </div>
                            <div className="form_fields_main mb-4">
                              <label>Product Description</label>
                              <Field
                                as="textarea"
                                name="desc"
                                className="form-control"
                                rows="5"
                                placeholder="Type Here..."
                              />
                              <ErrorMessage name="desc" component="div" />
                            </div>

                            <div className="form_fields_main mb-4">
                              <label>Product Link</label>
                              <Field
                                name="link"
                                as="textarea"
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                              />
                              <ErrorMessage name="link" component="div" />
                            </div>
                            <div className="form_fields_main mb-4">
                              <label>Price (£)</label>
                              <Field
                                name="price"
                                type="number"
                                className="form-control"
                                placeholder="£0.00"
                              />
                              <ErrorMessage name="price" component="div" />
                            </div>
                            <div className="form_fields_main mb-4 justify-content-end">
                              <button
                                type="submit"
                                className="create_cmn new c_create"
                                disabled={isSubmitting}
                              >
                                Edit Product
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
