import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../services/Api";
import { startstopLoading } from "../Reducer/loaderSlice";
import { toast } from "react-toastify";
import { constructQueryString } from "../../utils/commonFunctions";

const getAllUsers = createAsyncThunk(
  "getAllUsers",
  async (data, { dispatch }) => {
    dispatch(startstopLoading(true));
    const response = await API.get(
      `/getUsers?page=${data?.page == undefined ? 1 : data?.page}&limit=${data?.limit
      }&search=${data?.search ? data?.search : undefined}`
    );
    response.data && dispatch(startstopLoading(false));
    return response.data;
  }
);
const getUserDetail = createAsyncThunk(
  "getUserDetail",
  async (userId, { dispatch }) => {
    dispatch(startstopLoading(true));
    const response = await API.get(`/getUserDetails?user_id=${userId}`);
    response.data && dispatch(startstopLoading(false));
    return response.data;
  }
);

const getUserPurchase = createAsyncThunk(
  "getUserPurchase",
  async (data, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { userId, ...payload } = data;
    const queryString = constructQueryString(payload);
    const response = await API.get(`/userPurchases/${userId}${queryString ? `?${queryString}` : ""}`);
    response.data && dispatch(startstopLoading(false));
    return response.data;
  }
);

const deleteAccount = createAsyncThunk(
  "deleteAccount",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.delete(`/deleteUserAccount?user_id=${id}`);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const sendResetPass = createAsyncThunk(
  "sendResetPass",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(`/sendResetPass?id=${id}`);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const editUserDetails = createAsyncThunk(
  "editUserDetails",
  async (dataobj, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.put("/editUserDetails", dataobj);
    data && dispatch(startstopLoading(false));
    return data;
  }
);




const approveuserprofile = createAsyncThunk(
  "approveuserprofile",
  async (userobject, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.post("/approveuserprofile", userobject);
    data && dispatch(startstopLoading(false));

    toast.success(data?.data.adminapproved == 1 ? "User Approved" : "User Unapproved");
    return data;
  }
);

const changeUserRole = createAsyncThunk(
  "changeUserRole",
  async (userobject, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.put("/changestatus", userobject);
    console.log("data api", data)
    data && dispatch(startstopLoading(false));

    toast.success(data?.role == 1 ? "Role Changed to Creator" : "Role Changed to User");
    return data;
  }
);




export {
  deleteAccount,
  sendResetPass,
  editUserDetails,
  getAllUsers,
  getUserDetail,
  approveuserprofile,
  changeUserRole,
  getUserPurchase
};
