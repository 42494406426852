import React from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "./Include/Sidebar";

export default function Userpurchases() {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="create_tab">
            {/* <!-- start page title --> */}

            <div className="row align-items-center">
              <div className="col-6 d-flex align-items-center">
                <img src={require("../assets/images/Component.png")} />
                <h4 className="mb-sm-0 font-size-28">Sarahs Salon Wakefield</h4>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <p className="sub_heading">Joined: 26/08/2021</p>
              </div>
            </div>
          </div>
          {/* <!-- end page title --> */}

          <div className="row">
            <div className="col-lg-12 d-flex justify-content-end">
              <NavLink to="/User-overview">
                <button className="btn cre_new_one">Overview</button>
              </NavLink>
              <NavLink to="/User-Purchases">
                <button className="btn cre_new">Purchases</button>
              </NavLink>
            </div>
          </div>

          {/* <!-- start search row --> */}
          <div class="row mt-4 mb-3">
            <div class="col-xxl-4 col-xl-3 col-lg-3">
              <input
                type="text"
                class="form-control cmn_fields"
                id=""
                aria-describedby="emailHelp"
                placeholder="Search Here"
              />
            </div>

            <div class="col-xxl-2  d-xl-none d-lg-none"></div>

            <div className="col-xxl-2 col-xl-3 col-lg-3">
              <div class="form-group">
                <select
                  class="form-control cmn_fields"
                  id="exampleFormControlSelect2"
                >
                  <option>Courses Purchased : 3</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>

            <div className="col-xxl-2 col-xl-3 col-lg-3">
              <div class="form-group">
                <select
                  class="form-control cmn_fields"
                  id="exampleFormControlSelect3"
                >
                  <option>Courses Completed : 1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
            <div className="col-xxl-2 col-xl-3 col-lg-3">
              <div class="form-group">
                <select
                  class="form-control cmn_fields"
                  id="exampleFormControlSelect3"
                >
                  <option>Spend to Date : £20.00</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end search row --> */}

        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="members_tbl">
                <div className="card mt-4">
                  <div className="card-body">
                    <div className="searches_main d-flex flex-wrap justify-content-between w-75">
                      <div>
                        <h5>Purchase Date</h5>
                        <p>12/07/2023</p>
                        <p>08:30</p>
                      </div>
                      <div>
                        <h5>Course Title: </h5>
                        <p>Brow Tinting for Rookies</p>
                      </div>
                      <div>
                        <h5>Chapters</h5>
                        <ol>
                          <li>Course Introduction</li>
                          <li>The Basics</li>
                          <li>The Results</li>
                        </ol>
                      </div>
                      <div>
                        <h5>Status</h5>

                        <p className="status_com">Complete</p>
                        <p>23:00 / 33:22 minutes complete</p>
                        <p>Not Started</p>
                      </div>
                      <div>
                        <h5>Paid</h5>
                        <p>£10.00</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mt-4">
                  <div className="card-body">
                    <div className="searches_main d-flex justify-content-between w-75">
                      <div>
                        <h5>Purchase Date</h5>
                        <p>12/07/2023</p>
                        <p>08:30</p>
                      </div>
                      <div>
                        <h5>Course Title: </h5>
                        <p>Brow Tinting for Rookies</p>
                      </div>
                      <div>
                        <h5>Chapters</h5>
                        <ol>
                          <li>Course Introduction</li>
                          <li>The Basics</li>
                          <li>The Results</li>
                        </ol>
                      </div>
                      <div>
                        <h5>Status</h5>

                        <p className="status_com">Complete</p>
                        <p className="status_com">Complete</p>
                        <p className="status_com">Complete</p>
                      </div>
                      <div>
                        <h5>Paid</h5>
                        <p>£10.00</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mt-4">
                  <div className="card-body">
                    <div className="searches_main d-flex justify-content-between w-75">
                      <div>
                        <h5>Purchase Date</h5>
                        <p>12/07/2023</p>
                        <p>08:30</p>
                      </div>
                      <div>
                        <h5>Course Title: </h5>
                        <p>Brow Tinting for Rookies</p>
                      </div>
                      <div>
                        <h5>Chapters</h5>
                        <ol>
                          <li>Course Introduction</li>
                          <li>The Basics</li>
                          <li>The Results</li>
                        </ol>
                      </div>
                      <div>
                        <h5>Status</h5>

                        <p className="status_com">Complete</p>
                        <p>23:00 / 33:22 minutes complete</p>
                        <p>Not Started</p>
                      </div>
                      <div>
                        <h5>Paid</h5>
                        <p>£10.00</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- container-fluid --> */}
      </div>
      {/* <!-- End Page-content --> */}
    </div>
  );
}
