import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Layout from "../components/layout/Layout";

const PrivateRoute = () => {
  const adminToken = window.sessionStorage.getItem("adminToken");
  if (adminToken) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  } else {
    return <Navigate to={"/"} />;
  }
};

export default PrivateRoute;
