import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../services/Api";
import { startstopLoading } from "../Reducer/loaderSlice";

const getTags = createAsyncThunk("getTags", async ({ page, limit, search }) => {
  const { data } = await API.get(
    `/getTags?page=${page}&limit=${limit}&search=${search}`
  );
  return data;
});

const addTag = createAsyncThunk("addTag", async (obj) => {
  const { data } = await API.post("/addtages", obj);
  return data;
});
const editTag = createAsyncThunk("editTag", async (obj) => {
  const { data } = await API.put("/editTag", obj);
  return data;
});
const deleteTag = createAsyncThunk("deleteTag", async (id, { dispatch }) => {
  dispatch(startstopLoading(true));
  const { data } = await API.delete(`/deleteTag?id=${id}`);
  data && dispatch(startstopLoading(false));
  return data;
});

export { addTag, getTags, editTag, deleteTag };
