import React, { useState } from "react";
import SideBar from "./SideBar";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Dropdown, Row, Stack } from "react-bootstrap";

const Layout = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };
  const Pages = [
    "/dashboard",
    "/users",
    "/creators",
    "/channels",
    "/Transactions",
    "/ManageCourses",
    "/ManageProducts",
    "/AddTags",
    "/Manage-admin",
  ];

  const { pathname } = useLocation();

  return (
    <div id="layout-wrapper">
      <div className="toggle-icon">
        <button onClick={handleClick}>
          <img src={require("../../assets/images/menu.png")} />
        </button>
      </div>
      <div className={isActive ? "vertical-menu active" : "vertical-menu "}>
        <div data-simplebar="" className="h-100">
          <SideBar />
        </div>
      </div>
      {Pages.includes(pathname) && (
        <div className="page-title-right">
          {/* <!-- App Search--> */}

          <div className="d-flex justify-content-between align-items-center">
            <Row className="pt-1 profile-user-main">
              <Stack direction="horizontal" gap={3}>
                <div className="p-2 ms-auto">
                  <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <img src={require("../../assets/images/creator.png")} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menuarea shadow">
                      <Dropdown.Item>
                        <Link to="/ChangePassword" className="theme-color">
                          Change Password
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Stack>
            </Row>
          </div>
        </div>
      )}
      {children}
      {/* <Outlet /> */}
    </div>
  );
};

export default Layout;
