import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  editCreator,
  getCreatorDetails,
} from "../../redux/Action/creatorActions";
import { startstopLoading } from "../../redux/Reducer/loaderSlice";
import axios from "axios";
import countrieswDialCode from "../constansts/countriesWDialCode";

export default function EditCreator() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [prevImg, setPrevImg] = useState();
  const [galleryImages, setGallery] = useState();
  const [savedGall, setSaveGall] = useState();
  const [selectedCode, setSelectedCode] = useState();
  const [postCode, setPostCode] = useState();
  const [addressData, setAddressData] = useState();

  const { id } = useParams();
  const creatorDetails = useSelector((state) => state.creator.creatorDetails);
  const BASE_URL = process.env.REACT_APP_IMAGE_URL;
  useEffect(() => {
    dispatch(getCreatorDetails(id));
  }, []);

  useEffect(() => {
    if (creatorDetails) {
      setSaveGall(() => creatorDetails?.gallery?.map((gal) => gal));
      console.log(creatorDetails);
      setSelectedCode({
        label: creatorDetails?.country_code,
        value: creatorDetails?.country_code,
      });
      setPostCode(creatorDetails?.post_code);
    }
  }, [creatorDetails]);

  const themeMode = true;

  const postCodeClick = async (e) => {
    try {
      const url = `https://api.getAddress.io/find/${postCode}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      await axios
        .get(url)
        .then((res) => {
          const addressArr = res.data?.addresses;
          const addresses = addressArr?.map((adObj) => {
            let resObj = {};
            resObj.address = adObj?.formatted_address
              ?.filter((str) => str != "")
              ?.join(", ");
            resObj.post_code = res.data?.postcode;
            resObj.lat = res.data?.latitude;
            resObj.long = res.data?.longitude;
            resObj.country = adObj?.country;
            resObj.address1 = adObj?.line_1;
            adObj?.line_2 != "" && (resObj.address2 = adObj?.line_2);
            resObj.town = adObj?.town_or_city;
            resObj.city = adObj?.town_or_city;
            adObj?.line_3 &&
              (resObj.address2 = resObj.address2.concat(" ", adObj?.line_3));
            adObj?.line_4 &&
              (resObj.address2 = resObj.address2.concat(" ", adObj?.line_4));

            return resObj;
          });

          setAddressData(addresses);
        })
        .catch((err) => {
          if (
            err?.response?.data?.Message == "Bad Request: Invalid postcode."
          ) {
            toast.warning("Invalid Postcode");
          }
        });
      // const json = await response.json();
      // console.log(json);
      // setAddressApi(json);
    } catch (error) {
      console.log("error", error);
    }
  };

  console.log("addressData", addressData)

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      postCodeClick();
    }
  };

  const handleAddressChange = (e, setValue) => {
    const adObj = addressData[e.target.value];
    for (const key in adObj) {
      setValue(key, adObj[key]);
    }
  };

  const customStyles = {
    // control: (provided, state) => ({
    //   ...provided,
    //   width: 68,

    // }),

    // dropdownIndicator: (provided) => ({
    //   ...provided,
    //   width: "15px",
    //   height: "20px",
    //   padding: -5,
    //   marginLeft: "50px",
    //   marginTop: -40,
    //   color: themeMode ? "#6e6e6e" : "white",
    // }),

    singleValue: (provided) => ({
      ...provided,
      color: themeMode ? "#6e6e6e" : "white",
    }),

    control: (provided, state) => ({
      ...provided,
      width: 90,
      // backgroundColor: themeMode ? "white" : "#1e1e1e",
      backgroundColor: "#efefef",
      border: `1px solid ${themeMode ? "#cccccc" : "#444444"}`,
      height: "48px",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "1px solid #b79c6c" : "none",
      "&:hover": {
        border: "1px solid #b79c6c",
      },
      // borderColor: state.isFocused ? "#ab91ed" : "#282828",
    }),
    input: (provided) => ({
      ...provided,
      color: themeMode ? "#6e6e6e" : "white",
      // margin: "0px",
      // padding: "8px",
    }),
    menu: (provided) => ({
      ...provided,
      width: 200, // Set the width for the dropdown menu
    }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: (provided) => ({
      // ...provided,
      // with: "1px",
      ...provided,
      width: "15px",
      height: "20px",
      padding: -5,
      // marginRight: "5px",
      // marginTop: -43,
    }),
    clearIndicator: (provided) => ({
      // ...provided,
      display: "none",
    }),
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid px-0">
          <div className="create_tab">
            <div className="row align-items-center">
              <div className="col-12 d-flex align-items-center">
                <Link onClick={() => navigate(-1)}>
                  <img src={require("../../assets/images/Component.png")} />
                </Link>
                <h4 className="mb-sm-0 font-size-28">Edit Creator</h4>
              </div>
            </div>
          </div>
          {/* <!-- end page title --> */}
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={{
            email: creatorDetails?.email,
            full_name: creatorDetails?.full_name || "",
            profile_image: creatorDetails?.profile_image,
            bio: creatorDetails?.bio || "",
            gallery: creatorDetails?.gallery,
            specialism1: creatorDetails?.specialisms[0] || "",
            specialism2: creatorDetails?.specialisms[1] || "",
            specialism3: creatorDetails?.specialisms[2] || "",
            specialism4: creatorDetails?.specialisms[3] || "",
            dob: creatorDetails?.dob,
            address: creatorDetails?.address,
            post_code: creatorDetails?.post_code,
            lat: creatorDetails?.lat,
            long: creatorDetails?.long,
            country: creatorDetails?.country,
            address1: creatorDetails?.address1,
            address2: creatorDetails?.address2,
            town: creatorDetails?.town || "",
            city: creatorDetails?.city || "",
            phone_number: creatorDetails?.phone_number || "",
            x: creatorDetails?.x || "",
            whatsapp: creatorDetails?.whatsapp || "",
            linkedin: creatorDetails?.linkedin || "",
            youtube: creatorDetails?.youtube || "",
            instagram: creatorDetails?.instagram || "",
            facebook: creatorDetails?.facebook || "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required*";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.full_name) {
              errors.full_name = "Required*";
            }
            if (!values.bio) {
              errors.bio = "Required*";
            }
            if (!values.profile_image) {
              errors.profile_image = "Required*";
            }
            if (!values.gallery) {
              errors.gallery = "Required*";
            }
            if (!values.dob) {
              errors.dob = "Required*";
            }
            if (!values.address) {
              errors.address = "Required*";
            }
            if (!values.phone_number) {
              errors.phone_number = "Required*";
            }
            if (!selectedCode) {
              errors.phone_number = "Required*";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(startstopLoading(true));

            let formdata = new FormData();
            formdata.append("creator_id", id);
            formdata.append("profile_image", values.profile_image);
            Array.from(values.gallery)?.forEach((img) =>
              formdata.append("gallery", img)
            );
            formdata.append("email", values.email);
            formdata.append("full_name", values.full_name);
            formdata.append("bio", values.bio);
            // formdata.append(
            //   "specialisms",
            //   JSON.stringify(
            //     [
            //       values.specialism1,
            //       values.specialism2,
            //       values.specialism3,
            //       values.specialism4,
            //     ]?.filter((text) => text != "")
            //   )
            // );

            formdata.append("specialisms", JSON.stringify([values.specialism1,
            values.specialism2,
            values.specialism3,
            values.specialism4,]))

            formdata.append("dob", values?.dob);
            formdata.append("address", values?.address);
            formdata.append("post_code", values?.post_code);
            formdata.append("lat", values?.lat);
            formdata.append("long", values?.long);
            formdata.append("country", values?.country);
            formdata.append("address1", values?.address1);
            formdata.append("address2", values?.address2);
            formdata.append("town", values?.town);
            formdata.append("city", values?.city);
            formdata.append("phone_number", values?.phone_number);
            formdata.append("country_code", selectedCode.value);

            values?.x != "" && formdata.append("x", values?.x);
            values?.whatsapp != "" &&
              formdata.append("whatsapp", values?.whatsapp);
            values?.linkedin != "" &&
              formdata.append("linkedin", values?.linkedin);
            values?.youtube != "" &&
              formdata.append("youtube", values?.youtube);
            values?.instagram != "" &&
              formdata.append("instagram", values?.instagram);
            values?.facebook != "" &&
              formdata.append("facebook", values?.facebook);

            dispatch(editCreator(formdata)).then(({ payload }) => {
              if (payload.success) {
                toast.success(payload.message);
                navigate("/creators");
                dispatch(startstopLoading(false));
              } else if (!payload.success) {
                toast.warning(payload.message);
                dispatch(startstopLoading(false));
              }
            });

            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              {errors.password && touched.password && errors.password}

              <div className="container-fluid px-0">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="members_tbl">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                            <h4 className="title_text">Details</h4>
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="save-cmn"
                            >
                              Edit
                            </button>
                          </div>
                          <div className="contact_details create_channel create-creator">
                            <div className="form_fields_main mb-4">
                              <label>Profile Picture</label>
                              <div className="d-flex align-items-end">
                                <div className="img_uploaded_box">
                                  <img
                                    src={
                                      !prevImg
                                        ?
                                        creatorDetails?.profile_image
                                        : prevImg
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="up_m_btn">
                                  <input
                                    type="file"
                                    className="upload_field"
                                    placeholder="Upload Image"
                                    name="profile_image"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "profile_image",
                                        e?.target?.files[0]
                                      );
                                      setPrevImg(
                                        URL.createObjectURL(e?.target?.files[0])
                                      );
                                    }}
                                  />
                                  <p className="upload_btn">Upload Image</p>
                                </div>
                              </div>
                            </div>

                            <div className="form_fields_main mb-4">
                              <label>Name</label>
                              {/* <div className="f
                              lex-column width-60"> */}

                              <input
                                type="text"
                                className="form-control width-645"
                                name="full_name"
                                onChange={handleChange}
                                value={values.full_name}
                                placeholder="Type Here.."
                              />
                              {errors?.full_name && touched?.full_name && (
                                <span className="formik-errors">
                                  {errors?.full_name}
                                </span>
                              )}

                              {/*   <span className="formik-errors">
                                  {"Required*"}
                                </span>
                               </div> */}
                            </div>
                            <div className="form_fields_main mb-4">
                              <label>Account Holder Email</label>
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                                placeholder="Type Here.."
                              />
                              {errors?.email && touched?.email && (
                                <span className="formik-errors">
                                  {errors?.email}
                                </span>
                              )}
                            </div>
                            <div className="form_fields_main mb-4">
                              <label>Date of Birth</label>
                              <input
                                type="date"
                                className="form-control"
                                name="dob"
                                onChange={handleChange}
                                value={values.dob}
                                placeholder="Type Here.."
                              />
                              {errors?.dob && touched?.dob && (
                                <span className="formik-errors">
                                  {errors?.dob}
                                </span>
                              )}
                            </div>
                            <div className="form_fields_main mb-4 number-set">
                              <label>Mobile Number</label>
                              <Select
                                className="phone_select"
                                value={
                                  selectedCode
                                    ? {
                                      label: selectedCode.value,
                                      value: selectedCode.value,
                                    }
                                    : null
                                }
                                onChange={(e) => setSelectedCode(e)}
                                options={countrieswDialCode}
                                isClearable={true}
                                placeholder="+00"
                                styles={customStyles}
                              />
                              <input
                                type="number"
                                className="form-control phone-field"
                                name="phone_number"
                                onChange={handleChange}
                                value={values.phone_number}
                                placeholder="Type Here.."
                              />
                              {errors?.phone_number &&
                                touched?.phone_number && (
                                  <span className="formik-errors">
                                    {errors?.phone_number}
                                  </span>
                                )}
                            </div>
                            <div className="form_fields_main mb-3">
                              <label>Post Code</label>
                              <input
                                type="search"
                                className="form-control"
                                onChange={({ target: { value } }) =>
                                  setPostCode(value)
                                }
                                onKeyDown={handleKeyDown}
                                value={postCode}
                                // value={values.address}
                                placeholder="Type Here.."
                              />

                              {errors?.address && touched?.address && (
                                <span className="formik-errors">
                                  {errors?.address}
                                </span>
                              )}
                            </div>
                            <div className="form_fields_main">
                              <label>Address</label>
                              <select
                                className="form-control-new mb-4 mt-2 select_new"
                                onChange={(e) =>
                                  handleAddressChange(e, setFieldValue)
                                }
                              >
                                <option
                                  value="none"
                                  selected="selected"
                                  disabled
                                  hidden
                                >
                                  {values.address}
                                </option>
                                {addressData &&
                                  addressData?.map((add, i) => {
                                    return (
                                      <option value={i}>{add?.address}</option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid px-0">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="members_tbl">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                            <h4 className="title_text">More Details</h4>
                          </div>
                          <div className="contact_details create_creator create_channel">
                            <div className="form_fields_main mb-4">
                              <label>Bio</label>
                              <textarea
                                placeholder="Type Here..."
                                className="form-control"
                                rows="8"
                                name="bio"
                                onChange={handleChange}
                                value={values.bio}
                              />
                              {errors?.bio && touched?.bio && (
                                <span className="formik-errors">
                                  {errors?.bio}
                                </span>
                              )}
                            </div>
                            <div className="form_fields_main mb-4">
                              <label>X</label>
                              <input
                                type="text"
                                className="form-control"
                                name="x"
                                onChange={handleChange}
                                value={values.x}
                                placeholder="Type Here.."
                              />
                            </div>
                            <div className="form_fields_main mb-4">
                              <label>WhatsApp</label>
                              <input
                                type="text"
                                className="form-control"
                                name="whatsapp"
                                onChange={handleChange}
                                value={values.whatsapp}
                                placeholder="Type Here.."
                              />
                            </div>
                            <div className="form_fields_main mb-4">
                              <label>LinkedIn</label>
                              <input
                                type="text"
                                className="form-control"
                                name="linkedin"
                                onChange={handleChange}
                                value={values.linkedin}
                                placeholder="Type Here.."
                              />
                            </div>
                            <div className="form_fields_main mb-4">
                              <label>Youtube</label>
                              <input
                                type="text"
                                className="form-control"
                                name="youtube"
                                onChange={handleChange}
                                value={values.youtube}
                                placeholder="Type Here.."
                              />
                            </div>
                            <div className="form_fields_main mb-4">
                              <label>Instagram</label>
                              <input
                                type="text"
                                className="form-control"
                                name="instagram"
                                onChange={handleChange}
                                value={values.instagram}
                                placeholder="Type Here.."
                              />
                            </div>
                            <div className="form_fields_main mb-4">
                              <label>Facebook</label>
                              <input
                                type="text"
                                className="form-control"
                                name="facebook"
                                onChange={handleChange}
                                value={values.facebook}
                                placeholder="Type Here.."
                              />
                            </div>
                            <div className="form_fields_main d-flex flex-nowrap mb-4">
                              <label>Specialisms</label>
                              <div className="create-width">
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  placeholder="1."
                                  name="specialism1"
                                  onChange={handleChange}
                                  value={values.specialism1}
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  placeholder="2."
                                  name="specialism2"
                                  onChange={handleChange}
                                  value={values.specialism2}
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  placeholder="3."
                                  name="specialism3"
                                  onChange={handleChange}
                                  value={values.specialism3}
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  placeholder="4."
                                  name="specialism4"
                                  onChange={handleChange}
                                  value={values.specialism4}
                                />
                              </div>
                            </div>
                            <div className="form_fields_main d-flex flex-nowrap">
                              <label>Gallery</label>
                              {/* <input
                                name="gallery"
                                type="file"
                                multiple
                                onChange={(e) =>
                                  setFieldValue("gallery", e?.target?.files)
                                }
                              /> */}
                              <div className="upld-gallery-section">
                                <div className="up_m_btn">
                                  <input
                                    className="upload_field"
                                    placeholder="Upload Images"
                                    name="gallery"
                                    type="file"
                                    multiple
                                    onChange={(e) => {
                                      if (e?.target?.files?.length > 4) {
                                        toast.warning(
                                          "Upload is limited to 4 images"
                                        );
                                        return;
                                      } else if (
                                        e?.target?.files?.length <= 4
                                      ) {
                                        setFieldValue(
                                          "gallery",
                                          e?.target?.files
                                        );
                                        setGallery(() => {
                                          const imgs = Array.from(
                                            e?.target?.files
                                          )?.map((file) =>
                                            URL.createObjectURL(file)
                                          );
                                          return imgs;
                                        });
                                      }
                                    }}
                                  />
                                  <p className="upload_btn">Upload Images</p>
                                  {errors?.gallery && touched?.gallery && (
                                    <span className="formik-errors text-start">
                                      {errors?.gallery}
                                    </span>
                                  )}
                                </div>
                                <div className="gallery-show-img row">
                                  {galleryImages
                                    ? galleryImages?.map((image) => (
                                      <div className="col-md-3 pb-3">
                                        <div className="img-show">
                                          <img src={image} alt="" />
                                        </div>
                                      </div>
                                    ))
                                    : savedGall?.length > 0 &&
                                    savedGall?.map((image) => (
                                      <div className="col-md-3 pb-3">
                                        <div className="img-show">
                                          <img src={image} alt="" />
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>

        {/* <!-- container-fluid --> */}
      </div>
    </div>
  );
}
