import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../redux/Action/adminAction";
import { useState } from "react";
import moment from "moment/moment";
import Search from "../components/search/Search";
import Pagination from "../components/Pagination";
import { approveuserprofile } from "../redux/Action/userAction";

export default function Users() {
  const limit = 8;
  const [page, setPage] = useState();
  const [search, setSearch] = useState();
  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state?.adminAction?.users);
  const totalUsers = useSelector((state) => state?.adminAction?.totalUsers);
  useEffect(() => {
    dispatch(getAllUsers({ page, limit, search }));
  }, [page, limit, search]);

  const changestatus = (e, id) => {
    dispatch(
      approveuserprofile({
        adminapproved: e.target.value,
        id: id,
      })
    );
  };

  return (
    <div className="main-content">
      <div className="page-content">
        {/* <!-- start page title --> */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <div>
                <span className="small_text">Buff Browz</span>
                <div className="d-flex justify-content-center align-items-center">
                  <h4 className="mb-sm-0 font-size-28">All Users</h4>
                  <p className="total">{totalUsers}</p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end page title --> */}

          {/* <!-- start search row --> */}
          <div className="row mb-4">
            <Search setSearch={setSearch} />

            <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
              <div className="form-group">
                <select
                  className="form-control cmn_fields"
                  id="exampleFormControlSelect2"
                >
                  <option>Last Active</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>

            <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
              <div className="form-group">
                <select
                  className="form-control cmn_fields"
                  id="exampleFormControlSelect3"
                >
                  <option>Purchases</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end search row --> */}

        <div className="row">
          <div className="col-xl-12">
            <div className="members_tbl height-set">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                    <h4 className="title_text">Users</h4>
                  </div>

                  <div className="table-responsive">
                    <table
                      id=""
                      className="table dt-responsive dealers_table nowrap w-100"
                    >
                      <thead>
                        <tr>
                          <th>User ID</th>
                          <th>Name</th>
                          <th>
                            Date Joined{" "}
                            {/* <img
                              className="filt_arrow"
                              src={
                                require("../assets/images/greydownarrow.svg")
                                  .default
                              }
                            /> */}
                          </th>
                          <th>Contact Email</th>
                          <th>City </th>
                          <th>
                            Last Active{" "}
                            <img
                              className="filt_arrow"
                              src={
                                require("../assets/images/greydownarrow.svg")
                                  .default
                              }
                            />
                          </th>
                          <th>Purchases </th>
                          <th>Spend </th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody className="td_color">
                        {allUsers?.map((item, i) => {
                          return (
                            <tr>
                              <td>
                                {item?.user_no?.toString()?.padStart(3, "0")}
                              </td>
                              <td>
                                <Link to={`/userDetails/${item?._id}`}>
                                  {item?.full_name}
                                </Link>
                              </td>
                              <td>
                                {moment(item?.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td>{item?.email}</td>
                              <td>{item?.country}</td>
                              <td>
                                {moment(item?.lastLogin).format("DD/MM/YYYY")}
                              </td>
                              <td>--</td>
                              <td>£--</td>
                              <td>
                                <div className="form-check form-switch active-inactive-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    value={item?.adminapproved == 0 ? 1 : 0}
                                    checked={
                                      item?.adminapproved == 0 ? false : true
                                    }
                                    id="flexSwitchCheckDefault"
                                    onClick={(e) => {
                                      changestatus(e, item?._id);
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination
                totalStuff={totalUsers}
                limit={limit}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- container-fluid --> */}
    </div>
  );
}
