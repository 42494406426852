import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../services/Api";
import { updateProgressBar } from "../Reducer/courseSlice";
import { startstopLoading } from "../Reducer/loaderSlice";
import axios from "axios";
import { toast } from "react-toastify";

const getStuffForCourseCreation = createAsyncThunk(
  "getStuffForCourseCreation",
  async () => {
    const { data } = await API.get("/getStuffForCourseCreation");
    return data;
  }
);

const createCourse = createAsyncThunk(
  "createCourse",
  async (formdata, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.post("/createCourse", formdata);
    dispatch(startstopLoading(false));
    return data;
  }
);

const getCourses = createAsyncThunk(
  "getCourses",
  async ({ page, limit, search }, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(
      `/getCourses?page=${page}&limit=${limit}&search=${search}`
    );
    dispatch(startstopLoading(false));
    return data;
  }
);

const deleteCourse = createAsyncThunk(
  "deleteCourse",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.delete(`/deleteCourse?id=${id}`);
    dispatch(startstopLoading(false));
    return data;
  }
);

const deleteChapter = createAsyncThunk(
  "deleteChapter",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.delete(`/deleteChapter?id=${id}`);
    dispatch(startstopLoading(false));
    return data;
  }
);

const getCourseDetails = createAsyncThunk(
  "getCourseDetails",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(`/getCourseDetails?id=${id}`);
    dispatch(startstopLoading(false));
    return data;
  }
);

const editCourse = createAsyncThunk(
  "editCourse",
  async (formdata, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.put("/editCourse", formdata);
    dispatch(startstopLoading(false));
    return data;
  }
);

const createChapter = createAsyncThunk(
  "createChapter",
  async (formdata, { dispatch }) => {
    // dispatch(startstopLoading(true));
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_NAME}api/admin/createChapter`, formdata, {
      headers: {
        authorization: sessionStorage.getItem("adminToken"),
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(`Upload progress: ${percentCompleted}%`);
        //  setUploadProgress(percentCompleted);
      },
    });
    if (!data?.success) {
      toast.error(data?.message);
    }
    // dispatch(startstopLoading(false));
    return data;
  }
);

export const setUploadProgress = createAsyncThunk(
  'upload/setProgress',
  async (percent) => percent
);

const uploadVideoInBackground = createAsyncThunk(
  "uploadChapterPhotoVideo",
  async ({ obj, video_title }, { dispatch }) => {
    console.log(video_title, "video_title");
    let startTime = null;
    let previousLoaded = 0;
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_NAME}api/admin/uploadChapterPhotoVideo`, obj, {
      headers: {
        authorization: sessionStorage.getItem("adminToken"),
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // Set the start time when the first progress event occurs
        if (!startTime) {
          startTime = Date.now();
        }

        // Calculate the elapsed time in seconds
        const elapsedTime = (Date.now() - startTime) / 1000;

        // Calculate the upload speed in bytes per second
        const uploadSpeed = progressEvent.loaded / elapsedTime;

        // Calculate the remaining time in seconds
        const remainingTime = (progressEvent.total - progressEvent.loaded) / uploadSpeed;

        const minutes = Math.floor(remainingTime / 60);
        const seconds = Math.floor(remainingTime % 60);
        const remainingTimeFormatted = `${minutes}min ${seconds}sec`;

        console.log(`Upload progress: ${percentCompleted}%`);
        console.log(`Elapsed time: ${elapsedTime.toFixed(2)} seconds`);
        console.log(`Estimated remaining time: ${remainingTime.toFixed(2)} seconds`);

        console.log(`Upload progress--------->: ${remainingTimeFormatted}`);
        //  setUploadProgress(percentCompleted);
        dispatch(setUploadProgress({ percent: percentCompleted, uploadTime: remainingTimeFormatted, video_title: video_title?.chapter_title }));
      },
    });
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);


const getChapters = createAsyncThunk(
  "getChapters",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(`/getChapters?id=${id}`);
    dispatch(startstopLoading(false));
    return data;
  }
);

const getChapterDetails = createAsyncThunk(
  "getChapterDetails",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(`/getChapterDetails?id=${id}`);
    dispatch(startstopLoading(false));
    return data;
  }
);

const editChapter = createAsyncThunk(
  "editChapter",
  async (formdata, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.put("/editChapter", formdata);
    dispatch(startstopLoading(false));
    return data;
  }
);

const createQuiz = createAsyncThunk(
  "createQuiz",
  async (quizObj, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.post("/createQuiz", quizObj);
    dispatch(startstopLoading(false));
    return data;
  }
);

const getQuizDetails = createAsyncThunk("getQuizDetails", async (id, { dispatch }) => {
  dispatch(startstopLoading(true));
  const { data } = await API.get(`/getQuizDetails?id=${id}`);
  dispatch(startstopLoading(false));
  return data;
});

const editQuiz = createAsyncThunk("editQuiz", async (quizObj, { dispatch }) => {
  dispatch(startstopLoading(true));
  const { data } = await API.put("/editQuiz", quizObj);
  dispatch(startstopLoading(false));
  return data;
});

export {
  getStuffForCourseCreation,
  createCourse,
  getCourses,
  deleteCourse,
  getCourseDetails,
  editCourse,
  createChapter,
  getChapters,
  getChapterDetails,
  editChapter,
  createQuiz,
  editQuiz,
  getQuizDetails,
  uploadVideoInBackground,
  deleteChapter
};
