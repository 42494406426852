import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Sidebar from "./Include/Sidebar";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllManageAdmins } from "../redux/Action/adminAction";
import moment from "moment";
import CommonModal from "../components/Modals/CommonModal";
import Pagination from "../components/Pagination";

export default function Manageadmin() {
  const limit = 8;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [type, setType] = useState();
  const [id, setId] = useState();

  const positionObj = {
    0: "Super Admin",
    1: "Admin",
    2: "Developer",
  };
  const accessObj = {
    0: "Full Access",
    1: "View Only",
  };

  const admins = useSelector((state) => state.adminAction.admins);
  const totalAdmins = useSelector((state) => state.adminAction.totalAdmins);

  useEffect(() => {
    dispatch(getAllManageAdmins({ page, limit, search }));
  }, [page, limit, search]);

  const handleModalOpen = (type, admin_id) => {
    setShow(true);
    setType(type);
    setId(admin_id);
  };

  return (
    <div class="main-content">
      {type && (
        <CommonModal show={show} setShow={setShow} type={type} id={id} />
      )}
      <div class="page-content">
        {/* <!-- start page title --> */}
        <div class="row">
          <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
              <div>
                <span class="small_text">Buff Browz</span>
                <div className="d-flex justify-content-center align-items-center">
                  <h4 class="mb-sm-0 font-size-28">Administrators</h4>
                  <p className="total">{totalAdmins}</p>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- end page title --> */}
        </div>

        <div class="row">
          <div class="col-xl-12">
            <div class="members_tbl height-set">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                    <h4 class="title_text">Administrators</h4>
                    <span>
                      <NavLink to="/Create-admin">
                        {" "}
                        <button class="btn cre_new">Create New</button>
                      </NavLink>
                    </span>
                  </div>

                  <div class="table-responsive">
                    <table
                      id=""
                      class="table dt-responsive dealers_table nowrap w-100"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>
                            Position{" "}
                            <img
                              class="filt_arrow"
                              src={
                                require("../assets/images/greydownarrow.svg")
                                  .default
                              }
                            />
                          </th>
                          <th>
                            Created{" "}
                            <img
                              class="filt_arrow"
                              src={
                                require("../assets/images/greydownarrow.svg")
                                  .default
                              }
                            />
                          </th>
                          <th>Access Level</th>
                          <th>Email </th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody class="td_color">
                        {admins?.length > 0
                          ? admins?.map((admin) => {
                              return (
                                <tr>
                                  <td>{admin?.full_name}</td>
                                  <td>{positionObj[admin?.position]}</td>
                                  <td>
                                    {moment(admin?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>{accessObj[admin?.access_level]}</td>
                                  <td>{admin?.email}</td>
                                  <td>
                                    <Link to={`/editAdmin/${admin?._id}`}>
                                      <button className="button edit">
                                        Edit
                                      </button>
                                    </Link>
                                    <button
                                      onClick={() =>
                                        handleModalOpen(
                                          "deleteAdmin",
                                          admin?._id
                                        )
                                      }
                                      className="button delete"
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          : ""}

                        {/* <tr>
                            <td>John Smith</td>
                            <td>Admin</td>
                            <td>25/07/2023</td>
                            <td>Read Only</td>
                            <td>johnsmith@yopmail.com</td>
                            <td>
                              <button className="button edit">Edit</button>
                              <button className="button delete">Delete</button>
                            </td>
                          </tr>
                          <tr>
                            <td>John Smith</td>
                            <td>Admin</td>
                            <td>25/07/2023</td>
                            <td>Read Only</td>
                            <td>johnsmith@yopmail.com</td>
                            <td>
                              <button className="button edit">Edit</button>
                              <button className="button delete">Delete</button>
                            </td>
                          </tr>
                          <tr>
                            <td>John Smith</td>
                            <td>Admin</td>
                            <td>25/07/2023</td>
                            <td>Read Only</td>
                            <td>johnsmith@yopmail.com</td>
                            <td>
                              <button className="button edit">Edit</button>
                              <button className="button delete">Delete</button>
                            </td>
                          </tr> */}
                      </tbody>
                    </table>
                    {/* <div className="no-data-div">
                      <p className="no-data">No data Found</p>
                    </div> */}
                  </div>
                </div>
              </div>
              <Pagination
                totalStuff={totalAdmins}
                limit={limit}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- container-fluid --> */}
    </div>
  );
}
