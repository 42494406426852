export const modalData = {
  deleteAccount: {
    title: "Delete Account",
    body: "Are you sure you want to delete this account?",
  },
  resetPass: {
    title: "Reset Password",
    body: "Are you sure you want to reset this account password?",
  },
  deleteAdmin: {
    title: "Delete Admin",
    body: "Are you sure you want to delete this admin?",
  },
  deleteCourse: {
    title: "Delete Course",
    body: "Are you sure you want to delete this course?",
  },
  deleteProduct: {
    title: "Delete Product",
    body: "Are you sure you want to delete this product?",
  },
  deleteCreator: {
    title: "Delete Creator",
    body: "Are you sure you want to delete this creator?",
  },
  deleteChannel: {
    title: "Delete Channel",
    body: "Are you sure you want to delete this channel?"
  },
  deleteChapter: {
    title: "Delete Chapter",
    body: "Are you sure you want to delete this chapter?"
  }
};
