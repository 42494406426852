import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCreators } from "../redux/Action/creatorActions";
import moment from "moment";
import Pagination from "../components/Pagination";
import CommonModal from "../components/Modals/CommonModal";
import userPlaceholder from "../assets/images/usericon.svg";

export default function Creators() {
  const BASE_URL = process.env.REACT_APP_IMAGE_URL;
  const dispatch = useDispatch();
  const limit = 8;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [id, setId] = useState();
  const [show, setShow] = useState(false);
  const [type, setType] = useState();

  const creators = useSelector((state) => state.creator.creators);
  const totalCreators = useSelector((state) => state.creator.totalCreators);

  useEffect(() => {
    dispatch(getCreators({ page, limit, search }));
  }, [page, search]);

  return (
    <div class="main-content">
      <div class="page-content">
        {/* <!-- start page title --> */}
        <div class="row">
          <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
              <div>
                <span class="small_text">Buff Browz</span>
                <div className="d-flex justify-content-center align-items-center">
                  <h4 class="mb-sm-0 font-size-28">Creators</h4>
                  <p className="total">{totalCreators}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end page title --> */}

        {id && type && (
          <CommonModal show={show} setShow={setShow} type={type} id={id} />
        )}

        {/* <!-- start search row --> */}
        <div class="row mb-4">
          <div class="col-xxl-4 col-xl-3 col-lg-3 col-md-6 col-12">
            <input
              type="text"
              class="form-control cmn_fields"
              id=""
              aria-describedby="emailHelp"
              placeholder="Search Here"
            />
          </div>

          <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-6 col-12">
            <div class="form-group">
              <select
                class="form-control cmn_fields"
                id="exampleFormControlSelect1"
              >
                <option>Specialism</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </div>
          <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-6 col-12">
            <div class="form-group">
              <select
                class="form-control cmn_fields"
                id="exampleFormControlSelect1"
              >
                <option>Last Active</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </div>
          <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-6 col-12">
            <div class="form-group">
              <select
                class="form-control cmn_fields"
                id="exampleFormControlSelect1"
              >
                <option>Uploads</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </div>

          <div className="col-xxl-2 col-xl-12 col-lg-12 col-12 d-flex justify-content-end">
            <span>
              <Link to="/createCreator">
                <button class="btn create_cmn new responsive">Add New</button>
              </Link>
            </span>
          </div>
        </div>

        {/* <!-- end search row --> */}

        <div class="row">
          <div class="col-xl-12">
            <div class="members_tbl height-set">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                    <h4 class="title_text">Creators</h4>
                  </div>
                  <div class="table-responsive">
                    <table
                      id=""
                      class="table dt-responsive dealers_table nowrap w-100"
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th>Creator ID</th>
                          <th>Name</th>
                          <th>
                            Date Joined{" "}
                            <img
                              class="filt_arrow"
                              src={
                                require("../assets/images/greydownarrow.svg")
                                  .default
                              }
                            />
                          </th>
                          <th>Email</th>
                          <th>City</th>
                          <th>
                            Last Active{" "}
                            <img
                              class="filt_arrow"
                              src={
                                require("../assets/images/greydownarrow.svg")
                                  .default
                              }
                            />
                          </th>
                          <th>Uploads</th>
                          <th>Specialisms</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody class="td_color">
                        {creators?.length > 0 &&
                          creators?.map((creator) => {
                            return (
                              <tr>
                                <td>
                                  <img
                                    className="creator-img"
                                    src={
                                      creator?.profile_image
                                        ? creator?.profile_image
                                        : userPlaceholder
                                    }
                                  />
                                </td>
                                <td>
                                  {(creator?.user_no)
                                    .toString()
                                    .padStart(3, "0")}
                                </td>
                                <td>
                                  <Link to={`/editCreator/${creator?._id}`}>
                                    {creator?.full_name}
                                  </Link>
                                </td>
                                <td>
                                  {moment(creator?.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>{creator?.email}</td>
                                <td>{creator?.city}</td>
                                <td>
                                  {creator?.lastLogin
                                    ? moment(creator?.lastLogin).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "--"}
                                </td>
                                <td>--</td>
                                <td>--</td>
                                <td>
                                  <button
                                    onClick={() => {
                                      setId(creator?._id);
                                      setType("deleteCreator");
                                      setShow(true);
                                    }}
                                    className="btn create_cmn new responsive"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination
                totalStuff={totalCreators}
                limit={limit}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- container-fluid --> */}
    </div>
  );
}
