import { useRef, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./Include/Sidebar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createChapter, uploadVideoInBackground } from "../redux/Action/coursesAction";
import ProgressBar from "@ramonak/react-progress-bar";
import closeButton from "../assets/images/close.png"

export default function CreateChapter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { course_id } = useParams();
  const [prevImg, setPrevImg] = useState();
  const uploadProgress = useSelector((state) => state.course.progressBar);

  const videoRef = useRef(null);
  const [prevVideo, setPrevVideo] = useState();
  const [progressVideo, setProgressVideo] = useState(0);
  const [videoName, setvideoName] = useState("");

  console.log("uploadProgress", uploadProgress)

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid px-0">
          <div className="create_tab">
            {/* <!-- start page title --> */}

            <div className="row align-items-center">
              <div className="col-12 d-flex align-items-center">
                <Link className="back_img" to={`/Course-Overview/${course_id}`}>
                  <img
                    src={require("../assets/images/Expand_left.svg").default}
                  />
                </Link>
                <h4 className="mb-sm-0 font-size-28">Create New Chapter</h4>
              </div>
            </div>
          </div>
          {/* <!-- end page title --> */}
        </div>

        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="members_tbl">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                      <h4 className="title_text"> Details</h4>
                    </div>
                    <div className="contact_details create_channel create_course chap_one">
                      <Formik
                        initialValues={{
                          chapter_cover_image: "",
                          chapter_title: "",
                          chapter_desc: "",
                          learn: "",
                          video: "",
                        }}
                        validate={(values) => {
                          const errors = {};
                          if (!values?.chapter_cover_image) {
                            errors.chapter_cover_image = "Required*";
                          }
                          if (!values?.chapter_title) {
                            errors.chapter_title = "Required*";
                          }

                          if (!values?.chapter_desc) {
                            errors.chapter_desc = "Required*";
                          }
                          if (!values?.learn) {
                            errors.learn = "Required*";
                          }
                          if (!values?.video) {
                            errors.video = "Required*";
                          }

                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          const formdata = new FormData();
                          formdata.append("course_id", course_id);
                          formdata.append(
                            "chapter_cover_image",
                            values?.chapter_cover_image
                          );
                          formdata.append(
                            "chapter_title",
                            values?.chapter_title
                          );
                          formdata.append("chapter_desc", values?.chapter_desc);
                          formdata.append("learn", values?.learn);
                          dispatch(createChapter(formdata)).then((data) => {
                            if (data?.payload?.success) {
                              const formData2 = new FormData();
                              formData2.append(`video`, values?.video);
                              formData2.append("chapter_id", data?.payload?.data?._id);
                              dispatch(uploadVideoInBackground({ obj: formData2, video_title: data?.payload?.data })).then((item) => {
                                console.log(item, "itemitemitemitem");
                              });
                              toast.success(data?.payload?.message);
                              setTimeout(() => {
                                navigate(`/Course-Overview/${course_id}`);
                              }, 500);
                            } else if (!data?.payload?.success) {
                              toast.warning(data?.payload?.message);
                            }
                          });

                        }}
                      >
                        {({ values, isSubmitting, setFieldValue }) => (
                          <Form>
                            <div className="form_fields_main mb-4">
                              <label>Chapter Cover Image</label>

                              <div className="d-flex align-items-end">
                                <div className="img_uploaded_box">
                                  {" "}
                                  <img src={prevImg} alt="" />
                                </div>
                                <div className="up_m_btn">
                                  <input
                                    type="file"
                                    class="upload_field"
                                    placeholder="Upload Image"
                                    onChange={(e) => {
                                      e?.target?.files[0] &&
                                        setFieldValue(
                                          "chapter_cover_image",
                                          e?.target?.files[0]
                                        );
                                      e?.target?.files[0] &&
                                        setPrevImg(
                                          URL.createObjectURL(
                                            e?.target?.files[0]
                                          )
                                        );
                                    }}
                                  />
                                  <p className="upload_btn">Upload Image</p>
                                </div>
                              </div>
                            </div>

                            <div className="form_fields_main mb-4 flex_work">
                              <label>Chapter Title</label>
                              <Field
                                class="form-control"
                                type="text"
                                name="chapter_title"
                                placeholder="Type Here.."
                              />
                              <div className="error-msg">
                                <ErrorMessage
                                  name="chapter_title"
                                  component="div"
                                />
                              </div>
                            </div>

                            {/* Previous Uplaod Video */}
                            <div className="form_fields_main mb-4">
                              <label>Upload Video</label>
                              {/* <div className="up_m_btn">
                                <input
                                  type="file"
                                  class="upload_field"
                                  placeholder="Upload Image"
                                  onChange={(e) => {
                                    e?.target?.files[0] &&
                                      setFieldValue(
                                        "video",
                                        e?.target?.files[0]
                                      );
                                  }}
                                />
                                <p className="upload_btn">Upload</p>
                              </div>
                              <div className="upload_progress">
                                <p>{values?.video ? "100%" : "0%"}</p>
                              </div>
                              {uploadProgress > 0 && (
                                <ProgressBar
                                  now={uploadProgress}
                                  label={`${uploadProgress.toFixed(2)}%`}
                                />
                              )} */}
                              <div className={`form-group mb-5 video-new-upload ${values?.video || prevVideo ? `img-upload-additional` : ``}`} >
                                <h2 className="mt-3 text-center">Upload Video Here</h2>
                                <div className="d-flex justify-content-center">
                                  <button type="button">
                                    <input
                                      type="file"
                                      accept="video/*"
                                      name="video"

                                      onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                          const url = URL.createObjectURL(file);
                                          setPrevVideo(url);
                                          setvideoName(file.name);
                                        }
                                        setFieldValue("video", e.target.files[0]);

                                        const steps = [0, 100];
                                        steps.forEach((value, index) => {
                                          setTimeout(() => {
                                            setProgressVideo(value);
                                          }, index * 1000);
                                        });
                                      }} />Upload Video</button></div>
                                {
                                  videoName ?
                                    <div className="uploaded-video-view">
                                      <h3>{videoName}</h3>
                                      {/* <ProgressBar completed={uploadProgress || 0} bgColor="black" /> */}
                                      {/* <button type="button" className="cancel-btn"><img src={require("../images/cancelbtn.svg").default}/></button> */}
                                    </div>
                                    :
                                    typeof values?.video == 'string' && values?.video != '' ?
                                      <h6>{values?.video?.split('/').pop()}</h6> :
                                      <p className="text-center mt-4">No File Found</p>

                                }

                                {prevVideo ? (
                                  <>
                                    <button className="cancel-btn" type="button" onClick={() => {
                                      setPrevVideo()
                                      setvideoName();
                                      setProgressVideo(0);
                                      setFieldValue('video', '')
                                    }}><img src={closeButton} /></button>

                                    <video ref={videoRef}
                                      src={prevVideo}
                                      type="video/mp4"
                                      onLoadedMetadata={() => {
                                        if (videoRef.current) {
                                          videoRef.current.currentTime = 5; // Set to the time you want for the preview
                                        }
                                      }} controls>
                                    </video>
                                    <ProgressBar className="progress-line" completed={progressVideo} bgColor={progressVideo == 100 ? "#65c365" : "black"} height="5px" />
                                  </>
                                ) : (
                                  values.video && (
                                    <>
                                      <video ref={videoRef}
                                        src={values?.video}
                                        type="video/mp4"
                                        onLoadedMetadata={() => {
                                          if (videoRef.current) {
                                            videoRef.current.currentTime = 5; // Set to the time you want for the preview
                                          }
                                        }} controls>
                                      </video>

                                      <button className="cancel-btn" type="button" onClick={() => {
                                        setPrevVideo()
                                        setvideoName();
                                        setProgressVideo(0);
                                        setFieldValue('video', '')
                                      }}><img src={closeButton} /></button>
                                    </>
                                  )
                                )}

                              </div>
                            </div>


                            {/* Channel Description */}
                            <div className="form_fields_main mb-4 flex_work">
                              <label>Chapter Description</label>

                              <Field
                                as="textarea"
                                class="form-control"
                                type="text"
                                name="chapter_desc"
                                placeholder="Type Here.."
                              />

                              <div className="error-msg">
                                {" "}
                                <ErrorMessage
                                  name="chapter_desc"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="form_fields_main mb-4 flex_work">
                              <label>What You’ll Learn</label>
                              <Field
                                as="textarea"
                                class="form-control"
                                type="text"
                                name="learn"
                                placeholder="Type Here.."
                              />
                              <div className="error-msg">
                                <ErrorMessage name="learn" component="div" />
                              </div>
                            </div>

                            <div className="col-lg-12 d-flex justify-content-end ">
                              <button type="submit" className="create_cmn new">
                                Create Chapter
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- container-fluid --> */}
      </div>
      {/* <!-- End Page-content --> */}
    </div>
  );
}
