import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/Pagination";
import { getCourses } from "../redux/Action/coursesAction";
import moment from "moment";
import CommonModal from "../components/Modals/CommonModal";
import { NumericFormat } from "react-number-format";
import { currencyFormatter } from "../utils/currencyFormatter";

export default function ManageCourses() {
  const dispatch = useDispatch();
  const limit = 8;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [id, setId] = useState();
  const [type, setType] = useState();
  const [show, setShow] = useState(false);

  const totalCourses = useSelector((state) => state.course.totalCourses);
  const courses = useSelector((state) => state.course.courses);

  useEffect(() => {
    dispatch(getCourses({ page, limit, search }));
  }, [page, limit, search]);

  return (
    <Layout>
      {type && id && (
        <CommonModal show={show} setShow={setShow} type={type} id={id} />
      )}

      <div class="main-content">
        <div class="page-content">
          {/* <!-- start page title --> */}
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <div>
                  <span class="small_text">Buff Browz</span>
                  <div className="d-flex justify-content-center align-items-center">
                    <h4 class="mb-sm-0 font-size-28">All Courses </h4>
                    <p className="total">{totalCourses}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            {/* <!-- start search row --> */}
            <div class="row mb-4">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                <input
                  type="text"
                  class="form-control cmn_fields"
                  id=""
                  aria-describedby="emailHelp"
                  placeholder="Search Here"
                />
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                <div class="form-group">
                  <select
                    class="form-control cmn_fields"
                    id="exampleFormControlSelect1"
                  >
                    <option>Creator</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-3 d-flex justify-content-end">
                <span>
                  <NavLink to="/CreateCourse">
                    {" "}
                    <button class="btn create_cmn new">Create New</button>
                  </NavLink>
                </span>
              </div>
            </div>
          </div>
          {/* <!-- end search row --> */}

          <div class="row">
            <div class="col-xl-12">
              <div class="members_tbl height-set">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                      <h4 class="title_text">Courses</h4>
                    </div>

                    <div class="table-responsive">
                      <table
                        id=""
                        class="table dt-responsive dealers_table nowrap w-100"
                      >
                        <thead>
                          <tr>
                            <th>Course ID</th>
                            <th>Course Title</th>
                            <th>Creator</th>
                            <th>Category</th>
                            <th>
                              Created Date{" "}
                              <img
                                class="filt_arrow"
                                src={
                                  require("../assets/images/greydownarrow.svg")
                                    .default
                                }
                              />{" "}
                            </th>
                            <th>Price</th>
                            <th>Total Views</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody class="td_color">
                          {courses?.length > 0
                            ? courses?.map((course) => {
                                return (
                                  <tr>
                                    <td>
                                      {course?.course_id
                                        ?.toString()
                                        ?.padStart(3, "0")}
                                    </td>
                                    <td>
                                      <Link
                                        to={`/Course-Overview/${course?._id}`}
                                      >
                                        {course?.course_title}
                                      </Link>
                                    </td>
                                    <td>{course?.creator_id?.full_name}</td>
                                    <td>{course?.coursecategory?.title}</td>
                                    <td>
                                      {moment(course?.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {course?.cost
                                        ? currencyFormatter.format(course?.cost)
                                        : "--"}
                                    </td>
                                    <td>{"--"}</td>
                                    <td className="d-flex">
                                      <Link to={`/editCourse/${course?._id}`}>
                                        <button className="button edit">
                                          Edit
                                        </button>
                                      </Link>
                                      <button
                                        onClick={() => {
                                          setId(course?._id);
                                          setType("deleteCourse");
                                          setShow(true);
                                        }}
                                        className="button delete"
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                      {/* <div className="no-data-div">
                        <p className="no-data">No data Found</p>
                      </div> */}
                    </div>
                  </div>
                </div>
                <Pagination
                  totalStuff={totalCourses}
                  limit={limit}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- container-fluid --> */}
      </div>
    </Layout>
  );
}
