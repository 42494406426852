import React, { useEffect, useState } from "react";
import { NavLink, Link, useParams, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Include/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getChapters, getCourseDetails } from "../redux/Action/coursesAction";
import moment from "moment";
import Chapter from "../components/manageCourses/Chapter";
import { formatCurrency } from "../components/commonFunction";

export default function CourseOverview() {
  const BASE_URL = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const chapter_id_query = queryParams.get('chapter_id');


  // const chapter_id_query = query.search.split('?chapter_id=')[1]
  const [chapterId, setChapterId] = useState();

  const courseDetails = useSelector((state) => state?.course?.courseDetails);
  const chapters = useSelector((state) => state?.course?.chapters);

  console.log("chapters", chapters)

  const updateURL = (value) => {
    const newQueryParams = new URLSearchParams({
      "chapter_id": value
    })?.toString();
    console.log("newQueryParams", newQueryParams)
    setTimeout(() => {
      navigate(`?${newQueryParams}`);
    }, 500);
    // setChapterId(chapter_id_query)
  };


  useEffect(() => {
    dispatch(getCourseDetails(id));
    dispatch(getChapters(id));
  }, []);
  console.log("chapter_id_query", chapter_id_query)

  useEffect(() => {
    if (chapter_id_query)
      setChapterId(chapter_id_query)
  }, [chapter_id_query])


  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid px-0">
          <div className="create_tab">
            {/* <!-- start page title --> */}

            <div className="row align-items-center">
              <div className="col-6 d-flex align-items-center">
                <Link className="back_img" to="/ManageCourses">
                  <img
                    src={require("../assets/images/Expand_left.svg").default}
                  />
                </Link>
                <h4 className="mb-sm-0 font-size-28">
                  {courseDetails?.course_title}
                </h4>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <p className="sub_heading">
                  Created:{" "}
                  {moment(courseDetails?.createdAt).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          </div>
          {/* <!-- end page title --> */}

          <div className="row mb-4">
            <div className="col-lg-12 d-flex justify-content-end">
              <Link to={`/Course-Overview/${id}`}>
                <button
                  onClick={() => setChapterId()}
                  className={chapterId ? "btn cre_new_one" : "btn cre_new"}
                >
                  Overview
                </button>
              </Link>
              {chapters?.length > 0 &&
                chapters?.map((chapter, i) => {
                  return (
                    <button
                      key={i}
                      onClick={() => updateURL(chapter?._id)}
                      className={
                        chapterId == chapter?._id
                          ? "btn cre_new"
                          : "btn cre_new_one"
                      }
                    >
                      {chapter?.chapter_title}
                    </button>
                  );
                })}
              <Link to={`/Create-Chapter/${id}`}>
                <button className="btn cre_new_one">+</button>
              </Link>
            </div>
          </div>
        </div>

        {!chapterId ? (
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-xl-12">
                <div className="members_tbl">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                        <h4 className="title_text"> Details</h4>
                        <Link to={`/editCourse/${id}`}>
                          <button className="btn cre_new_one">Edit</button>
                        </Link>
                      </div>
                      <div className="course-overview-main">
                        <form>
                          <div className="row">
                            <div className="col-lg-6 mb-2">
                              <div className="row">
                                <div className="col-lg-4">
                                  <label>Course Cover Image</label>
                                </div>
                                <div className="col-lg-8">
                                  <div className="">
                                    <div className="img_uploaded_box">
                                      <img src={courseDetails?.cover_image} />
                                    </div>
                                    <div
                                      className="up_m_btn d-none"
                                      style={{ opacity: 0 }}
                                    >
                                      <input
                                        type="file"
                                        class="upload_field"
                                        id=""
                                        placeholder="Upload Image"
                                      />
                                      <p className="upload_btn">Upload Image</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="row h-100 align-items-center">
                                <div className="col-lg-4">
                                  <label className="set_custom text-center">
                                    Cost
                                  </label>
                                </div>
                                <div className="col-lg-8">
                                  <p>{formatCurrency((courseDetails?.cost || 0), "£")}</p>
                                  {/* <p>{`£${courseDetails?.cost}`}</p> */}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 mt-2 mb-2">
                              <div className="row">
                                <div className="col-lg-2">
                                  <label>Course Title</label>
                                </div>
                                <div className="col-lg-10">
                                  <p>{courseDetails?.course_title}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-4">
                                  <label>Category</label>
                                </div>
                                <div className="col-lg-8">
                                  <p>{courseDetails?.coursecategory?.title}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-4">
                                  <label className="set_custom text-center">
                                    Creator
                                  </label>
                                </div>
                                <div className="col-lg-8">
                                  <p>{courseDetails?.creator_id?.full_name}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12 mt-2">
                              <div className="row">
                                <div className="col-lg-2">
                                  <label>Course Description</label>
                                </div>
                                <div className="col-lg-10">
                                  <p style={{ whiteSpace: "break-spaces" }}>
                                    {courseDetails?.course_desc}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 mt-2">
                              <div className="row">
                                <div className="col-lg-2">
                                  <label>What You’ll Learn</label>
                                </div>
                                <div className="col-lg-10">
                                  <p>{courseDetails?.learn}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 mt-2">
                              <div className="row">
                                <div className="col-lg-2">
                                  <label>Products used</label>
                                </div>
                                <div className="col-lg-10">
                                  <p>
                                    {courseDetails?.products
                                      ?.map((pro) => pro?.name)
                                      .join(", ")}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 mt-2">
                              <div className="row">
                                <div className="col-lg-2">
                                  <label>Tags</label>
                                </div>
                                <div className="col-lg-10">
                                  <p>
                                    {courseDetails?.tags
                                      ?.map((tag) => tag?.title)
                                      .join(", ")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Chapter chapterId={chapterId} setChapterId={setChapterId} />
        )}
        {/* <!-- container-fluid --> */}
      </div>
      {/* <!-- End Page-content --> */}
    </div>
  );
}
