import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getUserPurchase } from "../../redux/Action/userAction";
import moment from "moment/moment";
import { capitalizeFirstLetter } from "../../utils/commonFunctions";

export default function UserPurchases({ id }) {
  const dispatch = useDispatch()
  const userPurchase = useSelector((state) => state.adminAction.userPurchase)
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (id) {
      dispatch(getUserPurchase({ userId: id, search: searchValue }));
    }
  }, [searchValue, dispatch])

  const handleSearch = (e) => {
    setSearchValue(e);
  };


  console.log("id", id, "userPurchase", userPurchase)
  return (
    <>
      {/* <div className="container-fluid"> */}
      {/* <div className="create_tab">
          {/* <!-- start page title --> */}

      {/* <div className="row align-items-center">
            <div className="col-6 d-flex align-items-center">
              <img src={require("../../assets/images/Component.png")} />
              <h4 className="mb-sm-0 font-size-28">Sarahs Salon Wakefield</h4>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <p className="sub_heading">Joined: 26/08/2021</p>
            </div>
          </div> 
        </div> */}
      {/* <!-- end page title --> */}

      {/* <div className="row">
          <div className="col-lg-12 d-flex justify-content-end">
            <NavLink to="/User-overview">
              <button className="btn cre_new_one">Overview</button>
            </NavLink>
            <NavLink to="/User-Purchases">
              <button className="btn cre_new">Purchases</button>
            </NavLink>
          </div>
        </div> */}

      {/* <!-- start search row --> */}
      <div class="row mt-4 mb-3">
        <div class="col-xxl-4 col-xl-3 col-lg-3">
          <input
            type="search"
            class="form-control cmn_fields"
            id=""
            aria-describedby="emailHelp"
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search Here"
          />
        </div>

        <div class="col-xxl-2  d-xl-none d-lg-none"></div>

        <div className="col-xxl-2 col-xl-3 col-lg-3">
          <div class="form-group">
            <select
              class="form-control cmn_fields"
              id="exampleFormControlSelect2"
            >
              <option>Courses Purchased : 3</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div>

        <div className="col-xxl-2 col-xl-3 col-lg-3">
          <div class="form-group">
            <select
              class="form-control cmn_fields"
              id="exampleFormControlSelect3"
            >
              <option>Courses Completed : 1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div>
        <div className="col-xxl-2 col-xl-3 col-lg-3">
          <div class="form-group">
            <select
              class="form-control cmn_fields"
              id="exampleFormControlSelect3"
            >
              <option>Spend to Date : £20.00</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* <!-- end search row --> */}

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 px-0">
            <div className="members_tbl">
              <div className="card mt-4">
                <div className="card-body">
                  {
                    Array.isArray(userPurchase?.transactions) && userPurchase?.transactions?.length > 0 ?
                      userPurchase?.transactions?.map((res, index) => {
                        return <>
                          <div className="searches_main overflow-scrol-set d-flex flex-wrap justify-content-between w-75" key={index+1}>
                            <div>
                              <h5>Purchase Date</h5>
                              <p>{moment(res?.createdAt).format('DD/MM/YYYY')}</p>
                              <p>{moment(res?.createdAt).format('hh:mm')}</p>
                            </div>
                            <div>
                              <h5>Course Title: </h5>
                              <p>{capitalizeFirstLetter(res?.course_title)}</p>
                            </div>
                            <div>
                              <h5>Chapters</h5>
                              {
                                Array.isArray(res?.chapters) && res?.chapters?.length > 0 ?
                                  res?.chapters?.map((data, index) => {
                                    return <>
                                      <p key={index+1}><span>{index + 1}.</span>{" "}{data?.chapter_title}</p>
                                    </>
                                  }) :
                                  <p>No Chapter</p>
                              }
                            </div>
                            <div>
                              <h5>Status</h5>
                              <p className={res?.payment_status == "paid" && "status_com"}>{res?.payment_status == "paid" ? "Completed" : 'In Progress'}</p>
                            </div>
                            <div>
                              <h5>Paid</h5>
                              <p>£{res?.amount || "0.00"}</p>
                            </div>
                          </div>
                        </>
                      })
                      : <p>No data Found</p>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
