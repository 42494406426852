import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  deleteProduct,
  getProductDetails,
  getProducts,
} from "../Action/productsAction";

const initialState = {
  products: null,
  totalProducts: null,
};
const productSlice = createSlice({
  name: "productSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.products = payload?.data?.products;
          state.totalProducts = payload?.data?.totalProducts;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(getProductDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.productDetails = payload?.data;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(deleteProduct.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.products = state?.products?.filter(
            (pro) => pro?._id != payload?.data?._id
          );
          state.totalProducts = state.totalProducts - 1;
          toast.success(payload.message);
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      });
  },
});
export default productSlice.reducer;
