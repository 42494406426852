import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  deleteCreator,
  getCreatorDetails,
  getCreators,
} from "../Action/creatorActions";

const initialState = {
  creators: null,
  totalCreators: null,
  creatorDetails: null,
};
const creatorSlice = createSlice({
  name: "creatorSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCreators.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.creators = payload?.data?.creators;
          state.totalCreators = payload?.data?.totalCreators;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(getCreatorDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.creatorDetails = payload?.data;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(deleteCreator.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.creators = state?.creators?.filter(
            (creator) => creator?._id != payload?.data?._id
          );
          state.totalCreators = state.totalCreators - 1;
          toast.success(payload.message);
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      });
  },
});
export default creatorSlice.reducer;
