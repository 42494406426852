import { configureStore } from "@reduxjs/toolkit";
import adminAuthSlice from "./Reducer/adminAuthSlice";
import adminSlice from "./Reducer/adminSlice";
import creatorSlice from "./Reducer/creatorSlice";
import loaderSlice from "./Reducer/loaderSlice";
import channelSlice from "./Reducer/channelSlice";
import tagSlice from "./Reducer/addTagSlice";
import courseSlice from "./Reducer/courseSlice";
import productSlice from "./Reducer/productSlice";
import transSlice from "./Reducer/transSlice";
export const store = configureStore({
  reducer: {
    adminAuth: adminAuthSlice,
    adminAction: adminSlice,
    creator: creatorSlice,
    loader: loaderSlice,
    channel: channelSlice,
    tag: tagSlice,
    course: courseSlice,
    product: productSlice,
    transaction: transSlice
  },
});
