import { Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { startstopLoading } from "../../redux/Reducer/loaderSlice";
import { toast } from "react-toastify";
import { createChannel } from "../../redux/Action/channelAction";

export default function CreateChannel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [prevImg, setPrevImg] = useState();
  const [prevProfileImg, setPrevProfileImg] = useState();

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid px-0">
          <div className="create_tab">
            {/* <!-- start page title --> */}

            <div className="row align-items-center">
              <div className="col-12 d-flex align-items-center">
                <Link onClick={() => navigate(-1)}>
                  <img src={require("../../assets/images/Component.png")} />
                </Link>
                <h4 className="mb-sm-0 font-size-28">Create New Channel</h4>
              </div>
            </div>
          </div>
          {/* <!-- end page title --> */}
        </div>

        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="members_tbl">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-start align-items-center flex-wrap mb-3 position-relative">
                      <h4 className="title_text">Details</h4>
                    </div>
                    <div className="contact_details create_channel">
                      <Formik
                        initialValues={{
                          email: "",
                          full_name: "",
                          channel_icon: "",
                          profile_image: ""
                        }}
                        validate={(values) => {
                          const errors = {};
                          if (!values.email) {
                            errors.email = "Required";
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                              values.email
                            )
                          ) {
                            errors.email = "Invalid email address";
                          }
                          if (!values?.full_name)
                            errors.full_name = "Required*";
                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          dispatch(startstopLoading(true));

                          let formdata = new FormData();
                          formdata.append("email", values.email);
                          formdata.append("full_name", values.full_name);
                          formdata.append("channel_icon", values.channel_icon);
                          formdata.append("profile_image", values.profile_image);
                          formdata.append("bio", values.bio);

                          dispatch(createChannel(formdata)).then(
                            ({ payload }) => {
                              if (payload.success) {
                                toast.success(payload.message);
                                navigate("/channels");
                                dispatch(startstopLoading(false));
                              } else if (!payload.success) {
                                toast.warning(payload.message);
                                dispatch(startstopLoading(false));
                              }
                            }
                          );

                          setSubmitting(false);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            {/* Channel Cover Image */}
                            <div className="content-set">
                              <div className="row mb-3">
                                <div className="col-lg-2">
                                  <label>Channel Cover Image</label>
                                </div>
                                <div className="col-lg-8">
                                  <div className="d-flex align-items-end">
                                    <div className="img_uploaded_box channel_cover">
                                      <img src={prevImg && prevImg} alt="" />
                                    </div>
                                    <div className="up_m_btn">
                                      <input
                                        type="file"
                                        className="upload_field"
                                        placeholder="Upload Image"
                                        name="channel_icon"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "channel_icon",
                                            e?.target?.files[0]
                                          );
                                          setPrevImg(
                                            URL.createObjectURL(
                                              e?.target?.files[0]
                                            )
                                          );
                                        }}
                                      />
                                      <p className="upload_btn">Upload Image</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Channel Profile Image treat as channel icon */}
                            <div className="content-set">
                              <div className="row mb-3">
                                <div className="col-lg-2">
                                  <label>Channel Icon</label>
                                </div>
                                <div className="col-lg-8">
                                  <div className="d-flex align-items-end">
                                    <div className="img_uploaded_box">
                                      <img src={prevProfileImg && prevProfileImg} alt="" />
                                    </div>
                                    <div className="up_m_btn">
                                      <input
                                        type="file"
                                        className="upload_field"
                                        placeholder="Upload Image"
                                        name="profile_image"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "profile_image",
                                            e?.target?.files[0]
                                          );
                                          setPrevProfileImg(
                                            URL.createObjectURL(
                                              e?.target?.files[0]
                                            )
                                          );
                                        }}
                                      />
                                      <p className="upload_btn">Upload Image</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Channel Name */}
                            <div className="row content-set">
                              <div className="col-lg-2 mb-3">
                                <label>Channel Name</label>
                              </div>
                              <div className="col-lg-8 mb-3">
                                {" "}
                                <input
                                  type="text"
                                  class="form-control"
                                  name="full_name"
                                  placeholder="Type Here..."
                                  onChange={handleChange}
                                  value={values?.full_name}
                                />
                              </div>
                            </div>
                            <div className="row content-set">
                              <div className="col-lg-2 mb-3">
                                {" "}
                                <label>Account Holder Email</label>
                              </div>
                              <div className="col-lg-8 mb-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="email"
                                  placeholder="Type Here..."
                                  onChange={handleChange}
                                  value={values?.email}
                                />
                              </div>
                            </div>
                            <div className="row content-set">
                              <div className="col-lg-2">
                                {" "}
                                <label>Bio</label>
                              </div>
                              <div className="col-lg-8">
                                <textarea 
                                  rows={3}
                                  class="form-control w-100"
                                  name="bio"
                                  placeholder="Type Here..."
                                  onChange={handleChange}
                                  value={values?.bio}
                                />
                              </div>
                            </div>

                            <div className="col-lg-12 mt-4 d-flex justify-content-end">
                              <button
                                type="submit"
                                className="create_cmn"
                                disabled={isSubmitting}
                              >
                                Create Channel
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- container-fluid --> */}
    </div>
  );
}
