import { createSlice } from "@reduxjs/toolkit";
// export const initialStateValue = false;

const loaderReducer = createSlice({
  name: "loaderReducer",
  initialState: { value: false },
  reducers: {
    startstopLoading: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { startstopLoading } = loaderReducer.actions;
export default loaderReducer.reducer;
