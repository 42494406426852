// import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { adminLogin } from "../redux/Action/AdminAuth";
import { useEffect } from "react";
import { NavLink } from "react-bootstrap";
import hideEye from "../../src/assets/images/hide_password.svg";
import showEye from "../../src/assets/images/show_password.svg";


export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  //   const [passHide, setPassHide] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.sessionStorage.getItem("adminToken")) {
      window.location.href = "/dashboard";
    }
  }, []);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8 || values.password.length > 10) {
      errors.password = "Invalid Password";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      dispatch(adminLogin(values));
    },
  });
  return (
    <div>
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-xl-6">
            <div className="auth-full-page-content p-md-10 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="my-auto">
                    <div className="mt-4">
                      <h2 className="p-color font-weight-semibold mb-4">
                        Sign In
                      </h2>
                      <form onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                          <label for="username" className="form-label">
                            Email<span className="blue_text">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email *"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                          {formik.errors.email && (
                            <p className="error">{formik.errors.email}</p>
                          )}
                          <div className="error"></div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label">
                            Password<span className="blue_text">*</span>{" "}
                          </label>
                          <div className="input-group auth-pass-inputgroup position-relative">
                            <input
                              type={showPassword ? 'text' : 'password'}
                              className="form-control"
                              placeholder="Password *"
                              name="password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                            />
                            <span className="input-eye-show" onClick={toggleShowPassword}>
                              {showPassword ? <img src={showEye} /> :
                                <img src={hideEye} />}
                            </span>
                          </div>
                          {formik.errors.password && (
                            <p className="error">{formik.errors.password}</p>
                          )}

                          <div className="error"></div>
                        </div>

                        <div className="mt-4 d-grid">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Sign In
                          </button>
                        </div>

                        <div className="d-flex justify-content-center mt-3">
                          <NavLink
                            to="/ForgotPassword"
                            className="font-weight-semibold"
                          >
                            Forgot password?
                          </NavLink>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="auth-full-bg bg-black pt-lg-5 p-4">
              <div className="bg-overlay">
                <div className="new_logo">
                  <img
                    src={require("../assets/images/BUFFEducation-Logo.png")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
