import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getAllTransaction } from "../Action/transactionAction";

const initialState = {
    transaction: [],
};
const transSlice = createSlice({
    name: "transSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllTransaction.fulfilled, (state, { payload }) => {
                if (payload?.status == "success") {
                    state.transaction = payload?.data;
                } else if (!payload) {
                    toast.error(payload.message);
                }
            })
    },
});
export default transSlice.reducer;
