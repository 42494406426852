import { createSlice } from "@reduxjs/toolkit";
import { adminLogin } from "../Action/AdminAuth";
import { toast } from "react-toastify";
const initialState = {
     
  };
const adminAuthSlice = createSlice({
    name: "adminAuth",
    initialState,
    reducers: {
      keepMeLoggedIn: (state, { payload }) => {
        
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(adminLogin.fulfilled, (state, { payload }) => {
          state.user = payload;
          if (payload.success) {
                window.sessionStorage.setItem("adminToken", payload?.data?.token);
                window.location.href = "/dashboard";
          }
          if (!payload.success) {
            toast.error(payload.message);
          }
        })
    },
  });
  export default adminAuthSlice.reducer;