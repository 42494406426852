import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Sidebar from "./Include/Sidebar";
import Layout from "../components/layout/Layout";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  addTag,
  deleteTag,
  editTag,
  getTags,
} from "../redux/Action/addTagsAction";
import Pagination from "../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { startstopLoading } from "../redux/Reducer/loaderSlice";
import { getStuffForCourseCreation } from "../redux/Action/coursesAction";
import Select from "react-select";
export default function AddTags() {
  const dispatch = useDispatch();
  const limit = 8;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [ifEdit, setIfEdit] = useState(false);

  const [delShow, setDelShow] = useState(false);
  const [delid, setDelId] = useState();

  const handleClose = () => {
    setShow(false);
    setIfEdit(false);
  };
  const handleShow = () => setShow(true);
  const handleDelShow = () => setDelShow(false);
  const handleDelOpen = () => setDelShow(true);

  const tags = useSelector((state) => state.tag.tags);
  const stuff = useSelector((state) => state.course.stuff);
  const categories = stuff?.categories;

  const totalTags = useSelector((state) => state.tag.totalTags);

  useEffect(() => {
    dispatch(startstopLoading(true));
    dispatch(getStuffForCourseCreation());

    dispatch(getTags({ page, limit, search }))
      .then(() => dispatch(startstopLoading(false)))
      .catch(() => dispatch(startstopLoading(false)));
  }, [page, limit, search]);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: 200,
      height: 10,
    }),
  };

  var t = 1;
  return (
    <div className="main-content">
      <div className="page-content">
        {/* <!-- start page title --> */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <div>
                <span className="small_text">Buff Browz</span>
                <div className="d-flex justify-content-center align-items-center">
                  <h4 className="mb-sm-0 font-size-28">All Tags # </h4>
                  <p className="total">{totalTags}</p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end page title --> */}

          {/* <!-- start search row --> */}
          <div className="row mb-4">
            <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-6">
              <input
                type="text"
                className="form-control cmn_fields"
                onChange={(e) => setSearch(e.target.value)}
                aria-describedby="emailHelp"
                placeholder="Search Here"
              />
            </div>

            <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-6 col-6 d-flex justify-content-end">
              <span>
                <Link>
                  {" "}
                  <button onClick={handleShow} className="btn create_cmn new">
                    Create New
                  </button>
                </Link>
              </span>
            </div>
          </div>
        </div>
        {/* <!-- end search row --> */}

        <div className="row">
          <div className="col-xl-12">
            <div className="members_tbl height-set">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                    <h4 className="title_text">Tags</h4>
                  </div>

                  <div className="table-responsive">
                    <table
                      id=""
                      className="table dt-responsive dealers_table nowrap w-100"
                    >
                      <thead>
                        <tr>
                          <th>Tag ID</th>
                          <th>Category</th>
                          <th>Tag Name</th>
                          <th>Actions</th>
                          {/* <th className="width-set"></th> */}
                        </tr>
                      </thead>

                      <tbody className="td_color">
                        {tags?.length > 0 &&
                          tags?.map((tag) => {
                            return (
                              <tr>
                                <td>{t++}</td>
                                <td>{tag?.coursecategory?.title}</td>
                                <td>{tag?.title}</td>
                                <td className="d-flex">
                                  <button
                                    onClick={() => {
                                      setIfEdit({
                                        id: tag?._id,
                                        title: tag?.title,
                                        coursecategory:
                                          tag?.coursecategory?._id,
                                      });
                                      handleShow();
                                    }}
                                    className="button edit"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleDelOpen();
                                      setDelId(tag?._id);
                                    }}
                                    className="button delete"
                                  >
                                    Delete
                                  </button>
                                </td>
                                {/* <td className="width-set"></td> */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {/* <div className="no-data-div">
                        <p className="no-data">No data Found</p>
                      </div> */}
                  </div>
                </div>
              </div>
              <Pagination
                totalStuff={totalTags}
                limit={limit}
                setPage={setPage}
              />
            </div>
          </div>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={{
            title: ifEdit ? ifEdit.title ?? "" : "",
            coursecategory: ifEdit ? ifEdit.coursecategory ?? "" : "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.title) {
              errors.title = "Required";
            }
            if (!values.coursecategory) {
              errors.coursecategory = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            dispatch(startstopLoading(true));
            if (ifEdit) {
              dispatch(editTag({ ...values, id: ifEdit?.id }))
                .then(() => dispatch(startstopLoading(false)))
                .catch(() => dispatch(startstopLoading(false)));
            } else {
              dispatch(addTag(values))
                .then(() => dispatch(startstopLoading(false)))
                .catch(() => dispatch(startstopLoading(false)));
            }
            setSubmitting(false);
            handleClose();
            resetForm();
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Modal
              show={show}
              onHide={handleClose}
              className="popup-main add-tags"
            >
              <Form>
                <Modal.Body>
                  <div className="popup-inner">
                    <p> {ifEdit ? "Edit Tag" : "Add New Tag"}</p>

                    <Field
                      type="text"
                      name="title"
                      className="form-control"
                      placeholder="Type Here..."
                    />
                    <ErrorMessage name="title" component="div" />
                  </div>
                  <Select
                    className="form-control"
                    name="coursecategory"
                    onChange={(e) => setFieldValue("coursecategory", e?.value)}
                    options={categories}
                    styles={customStyles}
                  />
                  <div className="error-msg">
                    <ErrorMessage name="coursecategory" component="div" />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                  >
                    {ifEdit ? "Edit" : "Create"}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          )}
        </Formik>

        {/* Delete tag modal */}

        {
          <Modal show={delShow} onHide={handleDelShow} className="popup-main">
            <Modal.Body>
              <div className="popup-inner">
                <p>Delete Tag?</p>
              </div>
              <p>Are you sure you want to delete this tag?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  dispatch(deleteTag(delid));
                  handleDelShow();
                }}
                variant="primary"
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        }
      </div>
      {/* <!-- container-fluid --> */}
    </div>
  );
}
