import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getChannelDetails, getChannels } from "../Action/channelAction";

const initialState = {
  channels: null,
  totalChannels: null,
  channelDetails: null
};
const channelSlice = createSlice({
  name: "channelSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChannels.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.channels = payload?.data?.channels;
          state.totalChannels = payload?.data?.totalChannels;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(getChannelDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.channelDetails = payload?.data;
        } else if (!payload.success) {
          toast.error(payload.message);
        }
      })
  },
});

export default channelSlice.reducer;
