import { createSlice } from "@reduxjs/toolkit";
import { addTag, deleteTag, editTag, getTags } from "../Action/addTagsAction";
import { toast } from "react-toastify";

const initialState = { tags: null, totalTags: null };

const addTagSlice = createSlice({
  name: "addTagSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTags.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.tags = payload.data.tags;
          state.totalTags = payload.data.totalTags;
        }
        if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(addTag.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.tags.push(payload.data);
          state.totalTags = state.totalTags + 1;
          toast.success(payload.message);
        }
        if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(editTag.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.tags = state.tags?.map((tag) =>
            tag?._id == payload?.data?._id ? payload?.data : tag
          );
          toast.success(payload.message);
        }
        if (!payload.success) {
          toast.error(payload.message);
        }
      })
      .addCase(deleteTag.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.tags = state.tags?.filter(
            (tag) => tag?._id != payload?.data?._id
          );
          toast.success(payload.message);
        }
        if (!payload.success) {
          toast.error(payload.message);
        }
      });
  },
});

export default addTagSlice.reducer;
