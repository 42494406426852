import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getChannels } from "../redux/Action/channelAction";
import moment from "moment";
import userPlaceholder from "../assets/images/usericon.svg";
import CommonModal from "../components/Modals/CommonModal";

export default function Channel() {
  const BASE_URL = process.env.REACT_APP_IMAGE_URL;
  const dispatch = useDispatch();
  const limit = 8;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [show, setShow] = useState(false);
  const [type, setType] = useState();
  const [id, setId] = useState();

  const channels = useSelector((state) => state.channel.channels);
  const totalChannels = useSelector((state) => state.channel.totalChannels);

  useEffect(() => {
    dispatch(getChannels({ page, limit, search }));
  }, []);

  const handleModalOpen = (type, admin_id) => {
    setShow(true);
    setType(type);
    setId(admin_id);
  };

  console.log("empty");

  return (
    <div class="main-content">
      {type && (
        <CommonModal
          show={show}
          setShow={setShow}
          type={type}
          id={id}
          page={page}
          search={search}
          limit={limit}
        />
      )}
      <div class="page-content">
        {/* <!-- start page title --> */}
        <div class="row">
          <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
              <div>
                <span class="small_text">Buff Browz</span>
                <div className="d-flex justify-content-center align-items-center">
                  <h4 class="mb-sm-0 font-size-28">Channels </h4>
                  <p className="total">{totalChannels}</p>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- end page title --> */}

          {/* <!-- start search row --> */}
          <div class="row mb-4">
            <div class="col-xxl-4 col-xl-3 col-lg-3 col-md-6 col-12">
              <input
                type="text"
                class="form-control cmn_fields"
                id=""
                aria-describedby="emailHelp"
                placeholder="Search Here"
              />
            </div>

            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-6 col-12">
              <div class="form-group">
                <select
                  class="form-control cmn_fields"
                  id="exampleFormControlSelect1"
                >
                  <option>Specialism</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-6 col-12">
              <div class="form-group">
                <select
                  class="form-control cmn_fields"
                  id="exampleFormControlSelect1"
                >
                  <option>Last Active</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-6 col-12">
              <div class="form-group">
                <select
                  class="form-control cmn_fields"
                  id="exampleFormControlSelect1"
                >
                  <option>Uploads</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>

            <div className="col-xxl-2 col-xl-12 col-lg-12 col-12 d-flex justify-content-end">
              <span>
                <Link to="/createChannel">
                  <button class="btn create_cmn new responsive">
                    Create New
                  </button>
                </Link>
              </span>
            </div>
          </div>
        </div>
        {/* <!-- end search row --> */}

        <div class="row">
          <div class="col-xl-12">
            <div class="members_tbl channel height-set">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                    <h4 class="title_text">Channels</h4>
                  </div>

                  <div class="table-responsive">
                    <table
                      id=""
                      class="table dt-responsive dealers_table nowrap w-100"
                    >
                      <thead>
                        <tr>
                          <th>Logo</th>
                          <th>Channel ID</th>
                          <th>Channel Name</th>
                          <th>
                            Date Created{" "}
                            <img
                              class="filt_arrow"
                              src={
                                require("../assets/images/greydownarrow.svg")
                                  .default
                              }
                            />
                          </th>
                          {/* <th>Account Holder Name</th> */}
                          <th>Email</th>
                          <th>
                            Last Active{" "}
                            <img
                              class="filt_arrow"
                              src={
                                require("../assets/images/greydownarrow.svg")
                                  .default
                              }
                            />
                          </th>
                          <th>Uploads </th>
                          <th>Specialisms </th>
                          <th>Actions </th>
                        </tr>
                      </thead>
                      <tbody class="td_color">
                        {channels?.length > 0 &&
                          channels?.map((channel) => (
                            <tr>
                              <td>
                                <img
                                  src={
                                    channel?.channel_icon
                                      ? channel?.channel_icon
                                      : userPlaceholder
                                  }
                                />
                              </td>
                              <td>{channel?.user_no}</td>
                              <Link to={`/editChannel/${channel?._id}`}>
                                <td>{channel?.full_name}</td>
                              </Link>
                              <td>
                                {moment(channel?.createdAt).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td>{channel?.email}</td>
                              {/* <td>sarah@ashed.co.uk</td> */}
                              <td>
                                {channel?.lastLogin
                                  ? moment(channel?.lastLogin).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "--"}
                              </td>
                              <td>{"--"}</td>
                              <td>{"--"}</td>
                              <td>
                                <button
                                  onClick={() => {
                                    setId(channel?._id);
                                    setType("deleteChannel");
                                    setShow(true);
                                  }}
                                  className="btn create_cmn new responsive"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}

                        {/* <tr>
                          <td>
                            <img src={require("../assets/images/brown.png")} />
                          </td>
                          <td>0001</td>
                          <td>The Brow Greek</td>
                          <td>25/07/2023</td>
                          <td>Tia Harrison</td>
                          <td>tia@tbg.co.uk</td>
                          <td>25/07/2023</td>
                          <td>31</td>
                          <td>3</td>
                        </tr> */}
                      </tbody>
                    </table>
                    {channels?.length == 0 && (
                      <div className="no-data-div">
                        <p className="no-data">No data Found</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- container-fluid --> */}
    </div>
  );
}
