import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import { editQuiz, getQuizDetails } from "../redux/Action/coursesAction";
import { toast } from "react-toastify";

const EditQuiz = () => {
  const questionObj = {
    questiontitle: "",
    option: [{ optiontitle: "", answer: false }],
    answer: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [questions, setQuestions] = useState([questionObj]);
  const quizDetails = useSelector((state) => state?.course?.quizDetails);
  const chapter_id = quizDetails?.chapter_id?._id;

  useEffect(() => {
    dispatch(getQuizDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (quizDetails) {
      const deepCopyQuestions = structuredClone(quizDetails?.question);
      setQuestions(deepCopyQuestions);
    }
  }, [quizDetails]);

  const handleAllChange = (params) => {
    console.log("handleAllChange params---", params)
    setQuestions((prev) => {
      const changeThis = [...prev];
      return SwitchFunc({ ...params, changeThis });
    });
  };

  const SwitchFunc = (params) => {
    console.log("swichFunc", params)
    const { key, change, e, question_index, option_index, changeThis } = params;

    switch (key) {
      case "changeQuestionTitle":
        changeThis[question_index][change] = e.target.value;
        break;
      case "changeOptionTitle":
        changeThis[question_index].option[option_index][change] = e.target.value;
        break;
      case "changeOptionAnswer":
        changeThis[question_index].option.forEach((opt, idx) => {
          opt.answer = idx === option_index;
        });
        changeThis[question_index].answer = option_index;
        break;
      case "deleteQuestion":
        changeThis.splice(question_index, 1);
        break;
      case "addQuestion":
        changeThis.push({ ...questionObj});
        break;
      case "addOption":
        if (changeThis[question_index].option.length < 4) {
          changeThis[question_index].option.push({ optiontitle: "", answer: false });
        }
        break;
      case "deleteOption":
        if (changeThis[question_index].answer === option_index) {
          changeThis[question_index].answer = "";
        }
        changeThis[question_index].option.splice(option_index, 1);
        break;
      default:
        break;
    }
    return changeThis;
  };

  const handleSubmit = async () => {
    let hasError = false;

    questions.forEach((question, question_index) => {
      if (!question.questiontitle) {
        toast.warning(`Question title for question ${question_index + 1} is missing`);
        hasError = true;
      } else if (question.option.some((option) => !option.optiontitle) || question.option.length < 2) {
        toast.warning(`Options for question ${question_index + 1} are missing`);
        hasError = true;
      } else if (question?.answer?.toString() === "") {
        toast.warning(`Answer for question ${question_index + 1} is missing`);
        hasError = true;
      }
    });

    if (hasError) return;

    // Create a deep copy of questions to avoid mutating the state directly
    const sanitizedQuestions = questions.map((question) => {
      const sanitizedQuestion = { ...question };

      if (!sanitizedQuestion._id) {
        delete sanitizedQuestion._id;
      }

      sanitizedQuestion.option = sanitizedQuestion.option.map((opt) => {
        const sanitizedOption = { ...opt };
        if (!sanitizedOption._id) {
          delete sanitizedOption._id;
        }
        return sanitizedOption;
      });

      return sanitizedQuestion;
    });

    const quizObj = { id, question: sanitizedQuestions };

    try {
      const response = await dispatch(editQuiz(quizObj));
      if (response?.payload?.success) {
        toast.success(response?.payload?.message);
        navigate(`/Course-Overview/${quizDetails?.chapter_id?.course_id}?chapter_id=${chapter_id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid px-0">
          <div className="create_tab">
            <div className="row align-items-center">
              <div className="col-6 d-flex align-items-center">
                <Link className="back_img" to="/ManageCourses">
                  <img src={require("../assets/images/Expand_left.svg").default} alt="Back" />
                </Link>
                <h4 className="mb-sm-0 font-size-28">Course Introduction - Quiz</h4>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 d-flex justify-content-end">
              <button onClick={handleSubmit} className="btn cre_new">
                Save
              </button>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="members_tbl create_quiz">
                {questions.map((question, question_index) => (
                  <div className="card mb-4" key={question_index}>
                    <div className="card-body">
                      <div className="d-flex justify-content-start align-items-center flex-wrap mb-3 position-relative">
                        <h4 className="title_text">Question {question_index + 1}</h4>
                      </div>
                      <div className="contact_details pb-2">
                        <div className="form_fields_main mb-3">
                          <label>Question Title</label>
                          <input
                            type="text"
                            className="form-control main-question"
                            placeholder="Type Here.."
                            onChange={(e) =>
                              handleAllChange({
                                key: "changeQuestionTitle",
                                change: "questiontitle",
                                e,
                                question_index,
                              })
                            }
                            value={question.questiontitle}
                          />
                        </div>

                        <div className="form_fields_extra mt-4">
                          <label>Set Answers</label>
                          <div className="extra_bottom d-flex align-items-top">
                            <div>
                              <ol>
                                <li className="justify-content-end correct-heading">Correct</li>
                                {question.option.map((option, option_index) => (
                                  <li key={option._id} className="justify-content-end add-ansers">
                                    <span>{option_index + 1}</span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Type Here.."
                                      onChange={(e) =>
                                        handleAllChange({
                                          key: "changeOptionTitle",
                                          change: "optiontitle",
                                          e,
                                          question_index,
                                          option_index,
                                        })
                                      }
                                      value={option.optiontitle}
                                    />
                                    <input
                                      className="form-check-input check-custom"
                                      type="radio"
                                      name={`radio${question_index}`}
                                      checked={option.answer}
                                      onChange={(e) =>
                                        handleAllChange({
                                          key: "changeOptionAnswer",
                                          change: "answer",
                                          e,
                                          question_index,
                                          option_index,
                                        })
                                      }
                                    />
                                    <button
                                      type="button"
                                      className="close-img"
                                      onClick={() =>
                                        handleAllChange({
                                          key: "deleteOption",
                                          option_index,
                                          question_index,
                                        })
                                      }
                                    >
                                      <img src={require("../assets/images/Close_square_fill.png")} alt="Delete" />
                                    </button>
                                  </li>
                                ))}
                                <li className="justify-content-end add-ansers">
                                  <img
                                    className="add-question"
                                    onClick={() =>
                                      handleAllChange({
                                        key: "addOption",
                                        question_index,
                                      })
                                    }
                                    src={require("../assets/images/plus.svg").default}
                                    alt="Add"
                                  />
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 d-flex justify-content-end">
                          <button
                            onClick={() =>
                              handleAllChange({
                                key: "deleteQuestion",
                                question_index,
                              })
                            }
                            className="btn delete_one"
                            type="button"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    onClick={() => handleAllChange({ key: "addQuestion" })}
                    className="btn cre_new_one add"
                  >
                    Add Question
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditQuiz;
