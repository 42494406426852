import React from "react";
import { Link } from "react-router-dom";

const Quiz = ({ quiz }) => {
  console.log(quiz);

  return (
    <>
      <div className="create-btn d-flex justify-content-end mb-4">
        <Link to={`/editQuiz/${quiz?._id}`}>
          <button className="btn cre_new_one">Edit Quiz</button>
        </Link>
      </div>

      <div className="card">
        <div className="card-body">
          {quiz?.question?.map((ques, i) => {
            console.log(ques);
            return (
              <div className="position-relative">
                <h4 className="title_text">Question {i + 1}</h4>
                <div className="chapter-with-quiz p-4">
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Question Title</label>
                    </div>
                    <div className="col-lg-10">
                      <div className="card-inner-text">
                        <p>{ques?.questiontitle}</p>
                      </div>
                    </div>
                    <div className="col-lg-2 mt-3">
                      <label>Set Answers</label>
                    </div>
                    <div className="col-lg-10 mt-3">
                      <div className="crct">
                        <p>Correct?</p>
                      </div>

                      {ques?.option?.map((op, op_index) => {
                        return (
                          <div className="card-inner-radios">
                            <span>{op_index + 1}.</span>
                            <p>{op?.optiontitle}.</p>
                            <input
                              class="form-check-input"
                              type="radio"
                              name={`flexRadioDefault${i}`}
                              id="flexRadioDefault1"
                              checked={op?.answer}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Quiz;
