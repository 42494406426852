import { useLocation } from "react-router-dom";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const serialNumber = (currentPage, limit, index) => {
    return (currentPage - 1) * limit + index + 1;
}

const constructQueryString = (obj) => {
    if (obj) {
        const queryString = Object.keys(obj)
            .filter(key => obj[key] !== "" && obj[key] !== null && obj[key] !== undefined)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
            .join('&');
        return queryString;
    } else {
        return ""
    }
}

const capitalizeFirstLetter = (string) => {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
        return `N/A`
    }
}

export {
    useQuery,
    constructQueryString,
    capitalizeFirstLetter,
    serialNumber
}