import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { startstopLoading } from "../../redux/Reducer/loaderSlice";
import { editChannel, getChannelDetails } from "../../redux/Action/channelAction";

export default function EditChannel() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [prevImg, setPrevImg] = useState();
    const [prevProfileImg, setPrevProfileImg] = useState();
    const { id } = useParams();
    const channelDetails = useSelector((state) => state.channel.channelDetails);
    const BASE_URL = process.env.REACT_APP_IMAGE_URL;

    useEffect(() => {
        dispatch(getChannelDetails(id));
    }, []);

    console.log(channelDetails, "channelDetails")

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid px-0">
                    <div className="create_tab">
                        <div className="row align-items-center">
                            <div className="col-12 d-flex align-items-center">
                                <Link onClick={() => navigate(-1)}>
                                    <img src={require("../../assets/images/Component.png")} />
                                </Link>
                                <h4 className="mb-sm-0 font-size-28">Edit Channel</h4>
                            </div>
                        </div>
                    </div>
                    {/* <!-- end page title --> */}
                </div>

                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        email: channelDetails?.email,
                        full_name: channelDetails?.full_name,
                        channel_icon: channelDetails?.channel_icon,
                        profile_image: channelDetails?.profile_image,
                        bio: channelDetails?.bio,
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                            errors.email = "Required*";
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = "Invalid email address";
                        }
                        if (!values.full_name) {
                            errors.full_name = "Required*";
                        }
                        if (!values.bio) {
                            errors.bio = "Required*";
                        }
                        if (!values.channel_icon) {
                            errors.channel_icon = "Required*";
                        }
                        if (!values.profile_image) {
                            errors.profile_image = "Required";
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        console.log(values, "values--")
                        dispatch(startstopLoading(true));

                        let formdata = new FormData();
                        formdata.append("id", id);
                        formdata.append("channel_icon", values?.channel_icon);
                        formdata.append("profile_image", values?.profile_image);
                        formdata.append("email", values?.email);
                        formdata.append("full_name", values?.full_name);
                        formdata.append("bio", values?.bio);

                        dispatch(editChannel(formdata)).then(({ payload }) => {
                            if (payload.success) {
                                toast.success(payload.message);
                                navigate("/channels");
                                dispatch(startstopLoading(false));
                            } else if (!payload.success) {
                                toast.error(payload.message);
                                dispatch(startstopLoading(false));
                            }
                        });

                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            {errors.password && touched.password && errors.password}

                            <div className="container-fluid px-0">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="members_tbl">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                                                        <h4 className="title_text">Details</h4>
                                                        <button
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                            className="save-cmn"
                                                        >
                                                            Edit
                                                        </button>
                                                    </div>
                                                    <div className="contact_details create_channel create-creator">
                                                        {/* Channel Cover Image */}
                                                        <div className="form_fields_main mb-4">
                                                            <label>Channel Cover Image</label>
                                                            <div className="d-flex align-items-end">
                                                                <div className="img_uploaded_box channel_cover">
                                                                    <img
                                                                        src={
                                                                            !prevImg
                                                                                ?
                                                                                channelDetails?.channel_icon
                                                                                : prevImg
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="up_m_btn">
                                                                    <input
                                                                        type="file"
                                                                        className="upload_field"
                                                                        placeholder="Upload Image"
                                                                        name="channel_icon"
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "channel_icon",
                                                                                e?.target?.files[0]
                                                                            );
                                                                            setPrevImg(
                                                                                URL.createObjectURL(e?.target?.files[0])
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p className="upload_btn">Upload Image</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Channel Icon */}
                                                        <div className="form_fields_main mb-4">
                                                            <label>Channel Icon</label>
                                                            <div className="d-flex align-items-end">
                                                                <div className="img_uploaded_box">
                                                                    <img
                                                                        src={
                                                                            !prevProfileImg
                                                                                ?
                                                                                channelDetails?.profile_image
                                                                                : prevProfileImg
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="up_m_btn">
                                                                    <input
                                                                        type="file"
                                                                        className="upload_field"
                                                                        placeholder="Upload Image"
                                                                        name="profile_image"
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "profile_image",
                                                                                e?.target?.files[0]
                                                                            );
                                                                            setPrevProfileImg(
                                                                                URL.createObjectURL(e?.target?.files[0])
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p className="upload_btn">Upload Image</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form_fields_main mb-4">
                                                            <label>Channel Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control width-645"
                                                                name="full_name"
                                                                onChange={handleChange}
                                                                value={values.full_name}
                                                                placeholder="Type Here.."
                                                            />
                                                            {errors?.full_name && touched?.full_name && (
                                                                <span className="formik-errors">
                                                                    {errors?.full_name}
                                                                </span>
                                                            )}

                                                            {/*   <span className="formik-errors">
                                  {"Required*"}
                                </span>
                               </div> */}
                                                        </div>
                                                        <div className="form_fields_main mb-4">
                                                            <label>Account Holder Email</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="email"
                                                                onChange={handleChange}
                                                                value={values.email}
                                                                placeholder="Type Here.."
                                                            />
                                                            {errors?.email && touched?.email && (
                                                                <span className="formik-errors">
                                                                    {errors?.email}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="form_fields_main mb-4 content-set">
                                                            <label>Bio</label>
                                                            <textarea
                                                                rows={3}
                                                                class="form-control"
                                                                name="bio"
                                                                placeholder="Type Here..."
                                                                onChange={handleChange}
                                                                value={values?.bio}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    )}
                </Formik>

                {/* <!-- container-fluid --> */}
            </div>
        </div>
    );
}
