import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../services/Api";
import { startstopLoading } from "../Reducer/loaderSlice";

const getAllUsers = createAsyncThunk(
  "getAllUsers",
  async (data, { dispatch }) => {
    dispatch(startstopLoading(true));
    const response = await API.get(
      `/getUsers?page=${data?.page == undefined ? 1 : data?.page}&limit=${
        data?.limit
      }&search=${data?.search ? data?.search : undefined}`
    );
    response.data && dispatch(startstopLoading(false));
    return response.data;
  }
);

const getUserDetail = createAsyncThunk(
  "getUserDetail",
  async (userId, { dispatch }) => {
    dispatch(startstopLoading(true));
    const response = await API.get(`/getUserDetails?user_id=${userId}`);
    response.data && dispatch(startstopLoading(false));
    return response.data;
  }
);

const requestlist = createAsyncThunk(
  "requestlist",
  async (jsondata, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.post("/auth/requestlist", jsondata);
    data && dispatch(startstopLoading(false));


    return data;
  }
);


const updatetherequest = createAsyncThunk(
  "updatetherequest",
  async (jsondata, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.post("/auth/updatetherequest", jsondata);
    data && dispatch(startstopLoading(false));
    return data;
  }
);






const createManageAdmin = createAsyncThunk(
  "createManageAdmin",
  async (jsondata, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.post("/createManageAdmin", jsondata);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const getAllManageAdmins = createAsyncThunk(
  "getAllManageAdmins",
  async ({ page, limit, search }, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(
      `/getAllManageAdmins?page=${page}&limit=${limit}&search=${search}`
    );
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const deleteAdmin = createAsyncThunk(
  "deleteAdmin",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.delete(`/deleteAdmin?id=${id}`);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const getAdminDetails = createAsyncThunk(
  "getAdminDetails",
  async (id, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.get(`/getAdminDetails?id=${id}`);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const editAdmin = createAsyncThunk(
  "editAdmin",
  async (values, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.put("/editAdmin", values);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

const changePassword = createAsyncThunk(
  "changePassword",
  async (values, { dispatch }) => {
    dispatch(startstopLoading(true));
    const { data } = await API.post("/changePassword", values);
    data && dispatch(startstopLoading(false));
    return data;
  }
);

export {
  getAllUsers,
  getUserDetail,
  createManageAdmin,
  getAllManageAdmins,
  deleteAdmin,
  getAdminDetails,
  editAdmin,
  changePassword,
  requestlist,
  updatetherequest
};
